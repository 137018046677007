import React, { useRef, useEffect, useState } from "react";

import SignIn from "../sign-in/sign-in.component.class";
import SignUp from "../sign-up/sign-up.component.class";

import Parallax from "parallax-js";
// import layer1 from "../../img/parallax/layer1.svg";
// import layer2 from "../../img/parallax/layer2.svg";
// import layer3 from "../../img/parallax/layer3.svg";
// import layer4 from "../../img/parallax/layer4.svg";
// import rocket from "../../img/parallax/rocket.svg";

import "./welcome-page.styles.scss";

import RetroButton from "../../components/retro/button/retro-button.component";
import BoxLayer from "../../components/retro/box-layer/box-layer.component";
import CreateDemo from "../../components/create-demo/create-demo.component";

import Navbar from "./Nav/Navbar";
import Footer from "./Nav/Footer";

import SectionProduct from "./Sections/product";
import SectionConsultancy from "./Sections/consultancy";
import SectionFeatures from "./Sections/features";
import SectionCollaboration from "./Sections/collaboration";
import SectionPricing from "./Sections/pricing";
import SectionResources from "./Sections/resources";

import { motion } from "framer-motion";

function FadeInToBottom({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, translateY: "0px" },
        hidden: { opacity: 0, translateY: "-50px" },
      }}
      // className="collab__visual"
    >
      {children}
    </motion.div>
  );
}

const WelcomePage = ({ currentUser }) => {
  // const sceneEl = useRef(null);
  const [showRegister, setShowRegister] = useState(false);
  const [showDemo, setShowDemo] = useState(false);

  // useEffect(() => {
  //   const parallaxInstance = new Parallax(sceneEl.current, {
  //     relativeInput: true,
  //   });

  //   parallaxInstance.enable();

  //   return () => parallaxInstance.disable();
  // }, []);

  return (
    <div className="welcomePage">
      {/* <Navbar /> */}
      <SectionProduct />
      <SectionFeatures />
      <SectionCollaboration />
      <SectionPricing currentUser={currentUser} />
      <SectionConsultancy />
      <SectionResources />
      <div>
        <div className="layer-startUp">
          {/* <div classname="antLogy__logo-container">
                <img
                  src="/antlogy_logo.png"
                  width="400px"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div> */}
          <div className="welcomePage__message">
            <FadeInToBottom>
              <h3
                style={{
                  fontWeight: 600,
                  textDecoration: "underline 2px #1A82C4",
                }}
              >
                Achieve more with Antlogy
              </h3>
            </FadeInToBottom>
          </div>
          <div className="welcomePage__message">
            <h5>
              BETA VERSION of your new Life & Project Manager IS NOW RELEASED!
              🎉
            </h5>
            <h5>NEW FEATURES ARE ALSO ON THE WAY! STAY TUNED... </h5>
          </div>
          <div className="wp__btns">
            {/* <div className="wp__btns-try">
                <RetroButton mode="gray" onClick={() => setShowDemo(!showDemo)}>
                  Try Demo
                </RetroButton>
              </div> */}
            <div className="wp__btns-register">
              <RetroButton onClick={() => setShowRegister(!showRegister)}>
                Get Started for Free
              </RetroButton>
            </div>
          </div>
        </div>

        {showRegister && (
          <BoxLayer setLayer={setShowRegister}>
            <SignUp />
          </BoxLayer>
        )}
        {/* {showDemo && (
          <BoxLayer setLayer={setShowDemo}>
            <CreateDemo />
          </BoxLayer>
        )} */}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default WelcomePage;
