import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Picker } from "emoji-mart";
import RetroButton from "../../retro/button/retro-button.component";

import { db, fieldValue } from "../../../firebase/firebase.utils";

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

import { dummycaldata } from "./dummycaldata";

import ActivityCalendar, { ThemeInput } from "react-activity-calendar";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import moment from "moment";

import { IoArchiveOutline } from "react-icons/io5";
import { BsPlusLg, BsLink } from "react-icons/bs";
import { TfiLink, TfiUnlink } from "react-icons/tfi";

import {
  TbTransform,
  TbBrandLinkedin,
  TbBrandFacebook,
  TbBrandInstagram,
  TbWorldWww,
  TbUserCheck,
  TbUserPlus,
  TbUserX,
  TbAffiliate,
} from "react-icons/tb";

import {
  productivityClients,
  growthClients,
  healthClients,
  homeClients,
  financialClients,
  socialClients,
  selfcareClients,
} from "./lead.templates";

import "./lead-client.styles.scss";

export default function Client({
  currentUser,
  client,
  clientIcon,
  setClientIcon,
  clientName,
  setClientName,
  handleCloseClient,
  handleSaveClient,
  handleDeleteClient,
  clientmodalMode,
  clientPlatform,
  setClientPlatform,
  clientProjects,
  setClientProjects,
  clientEmail,
  setClientEmail,
  clientPhone,
  setClientPhone,
  referredBy,
  setReferredBy,
  type,
  setType,
  clientSocialLink,
  setClientSocialLink,
  clientWebLink,
  setClientWebLink,
  clientNotes,
  setClientNotes,
  activeTab,
  handleConvertClient,
  currentSpaceId,
  setSelectedDate,
}) {
  const stationData = useSelector((state) => state.space.stationData);
  const [isOpen, setIsOpen] = useState(false);

  const [clientHistory, setClientHistory] = useState([]);

  const [isLinked, setIsLinked] = useState("");

  useEffect(() => {
    if (!client) return;
    if (clientmodalMode === "add") return;
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .doc(client.id)
      .onSnapshot((doc) => {
        const refarray = doc.data().history;
        const array = [...refarray]
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((item) => {
            return {
              count: item.checked,
              date: item.date,
              level: item.checked,
            };
          });
        setClientHistory([...array]);
      });
    return () => {
      unsub();
    };
  }, []);

  // useEffect(() => {
  //   if (client.history.length > 0) {
  //     console.log("currenthistory is:", client.history);
  //     // const transformedhistory = client.history.map((item) => {
  //     //   return {
  //     //     count: item.checked,
  //     //     date: item.date,
  //     //     level: item.checked,
  //     //   };
  //     // });
  //     // setClientHistory([...transformedhistory]);
  //   }
  // }, []);

  const onSelectEmoji = (emojiObject) => {
    setClientIcon(emojiObject.native);
    setIsOpen(!isOpen);
  };

  const handlesetEmojiOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  // const handleTemplateSelect = (clienttemplate) => {
  //   setClientName(clienttemplate.name);
  //   setClientIcon(clienttemplate.icon);
  //   setStep(clienttemplate.step);
  //   setStepdone(0);
  //   setSteps(clienttemplate.steps);
  //   setUnit(clienttemplate.unit);
  // };

  // var todayutc = moment().utc().startOf('day'); // today in UTC
  // const printrule = rrulestr(client.refrrule)

  const minimalTheme = {
    light: ["hsl(0, 0%, 92%)", "rebeccapurple"],
    // dark: the default theme will be used as fallback
  };

  const checkedTheme = {
    light: ["#EBEBEB", "#1d1d1d", "#1d1d1d", "#1d1d1d", "#1d1d1d"],
    dark: ["#383838", "#fff", "#fff", "#fff", "#fff"],
  };

  const explicitTheme = {
    light: ["#EBEBEB", "#96BBD2", "#6FA2C3", "#4889B1", "#2270A1"],
    dark: ["#383838", "#4D455D", "#7DB9B6", "#F5E9CF", "#E96479"],
  };

  const handleLinktoProject = (e) => {
    e.preventDefault();
    const clientProjectsRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .doc(client.id);
    clientProjectsRef.update({
      projects: fieldValue.arrayUnion(e.target.value),
    });
    // setIsOpen(!isOpen);
  };

  const handleUnlink = (project) => {
    const clientProjectsRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("clients")
      .doc(client.id);
    clientProjectsRef.update({
      projects: fieldValue.arrayRemove(project),
    });
  };

  return (
    <div className="client">
      <div
        className="client-backdrop"
        onClick={() => handleCloseClient()}
      ></div>
      <div className="client-content">
        {clientmodalMode === "edit" && (
          <div className="client-history">
            <details open>
              <summary>
                <h3>Follow-up History</h3>
              </summary>
              <div className="activitycal-container">
                <ActivityCalendar
                  data={clientHistory}
                  renderBlock={(block, activity) =>
                    React.cloneElement(block, {
                      "data-tooltip-id": "react-tooltip-client",
                      "data-tooltip-html":
                        activity.count > 0
                          ? `Checked in on ${activity.date} 🫱‍🫲`
                          : `No contact on ${activity.date}`,
                    })
                  }
                  theme={checkedTheme}
                  hideColorLegend={true}
                  hideTotalCount={true}
                  hideMonthLabels={false}
                  showWeekdayLabels={true}
                  labels={{
                    // legend: {
                    //   less: "Less",
                    //   more: "More",
                    // },
                    months: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    // totalCount: "{{count}} checks in {{year}}",
                    totalCount: " ",
                    weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  }}
                />
                <Tooltip id="react-tooltip-client" />
              </div>
            </details>
          </div>
        )}

        <button
          className="client__exit__button"
          onClick={() => handleCloseClient()}
        >
          <BsPlusLg size="1.7em" />
        </button>
        <section className="client__projects-sec">
          <div className="client__projects-header">
            <h3>Linked Projects</h3>
            {isLinked === "" ? (
              <button onClick={() => setIsLinked("beingset")}>
                <TfiLink size="1.5em" />
              </button>
            ) : (
              <div className="client-form-header-link">
                <select onChange={handleLinktoProject}>
                  <option value="" disabled selected>
                    Link a Project
                  </option>
                  {stationData.map((project) => (
                    <option
                      key={project.id}
                      value={project.id}
                      // selected={selectedPhaseId === project.id}
                      className="tm_option"
                    >
                      {project.emojicon}
                      {project.name}
                    </option>
                  ))}
                </select>
                <button onClick={() => setIsLinked("")}>
                  <TfiUnlink size="2em" />
                </button>
              </div>
            )}
          </div>
          {client.projects.length < 1 && (
            <p>No projects with this client yet</p>
          )}
          <div className="client__projects">
            {client.projects.map((project, index) => (
              <div key={index} value={project} className="client__project">
                <Link to={`e/${project}/b`}>
                  {stationData.find((p) => p.id === project).emojicon}
                  {stationData.find((p) => p.id === project).name}
                </Link>
                <button onClick={() => handleUnlink(project)}>
                  <TfiUnlink />
                </button>
              </div>
            ))}
          </div>
        </section>
        {activeTab === "Client" && (
          <div className="client-templates">
            <details>
              <summary>
                <h3>Billings</h3>
              </summary>
              <p>Coming feature 😊</p>
            </details>
          </div>
        )}

        <div className="client-form-header">
          <h3>{activeTab}</h3>
          <div className="client-lead-btngroup">
            <div
              className="client-lead-convert-btn"
              onClick={handleConvertClient}
              data-tooltip-id="client-convert-tooltip"
              data-tooltip-content={
                activeTab === "Lead"
                  ? "Convert to client"
                  : "Convert to lead"
              }
            >
              <TbTransform />
            </div>
            <Tooltip id="client-convert-tooltip" />
            <button
              className="client-archive-btn"
              data-tooltip-id="archive-tooltip"
              data-tooltip-content={
                activeTab === "Lead"
                  ? "Archive this lead"
                  : "Archive this client"
              }
            >
              <IoArchiveOutline />
            </button>
            <Tooltip id="archive-tooltip" />
            {/* <p>Related Workspace</p> */}
          </div>
        </div>
        <form className="client-form">
          <div className="client-progress-icon">
            <span>
              {clientPlatform === "LinkedIn" && <TbBrandLinkedin />}
              {clientPlatform === "Facebook" && <TbBrandFacebook />}
              {clientPlatform === "Instagram" && <TbBrandInstagram />}
              {clientPlatform === "Web" && <TbWorldWww />}
              {clientPlatform === "Client Referral" && <TbUserCheck />}
              {clientPlatform === "Friend Referral" && <TbUserPlus />}
              {clientPlatform === "Former Client" && <TbUserX />}
              {clientPlatform === "Affiliate" && <TbAffiliate />}
            </span>
          </div>

          <div className="clientsettings">
            {/* <label>Client name / company</label> */}
            <div className="clientnameinput">
              {clientName === "" ? (
                <input
                  type="text"
                  value={clientName}
                  placeholder="Enter name or company name"
                  onChange={(e) => setClientName(e.target.value)}
                ></input>
              ) : (
                <input
                  type="text"
                  value={clientName}
                  placeholder="Enter name or company name"
                  onChange={(e) => setClientName(e.target.value)}
                ></input>
              )}
            </div>
            <div className="clientoptions">
              <label>Type</label>
              <select
                value={clientPlatform}
                onChange={(e) => setClientPlatform(e.target.value)}
              >
                <optgroup label="Social Media">
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                </optgroup>
                <option value="Web">Web</option>
                <option value="Client Referral">Client Referral</option>
                <option value="Friend Referral">Friend Referral</option>
                <option value="Former Client">Former Client</option>
                <option value="Affiliate">Affiliate</option>
              </select>
              <input
                type="url"
                value={clientSocialLink}
                placeholder="Link"
                onChange={(e) => setClientSocialLink(e.target.value)}
              ></input>
            </div>
            <div className="clientoptions">
              <input
                type="email"
                value={clientEmail}
                placeholder="Enter email"
                onChange={(e) => setClientEmail(e.target.value)}
              ></input>
              <input
                type="tel"
                value={clientPhone}
                placeholder="Phone number"
                onChange={(e) => setClientPhone(e.target.value)}
              ></input>
            </div>
            <div className="clientoptions">
              <input
                type="text"
                value={referredBy}
                placeholder="Referred by (if so...)"
                onChange={(e) => setReferredBy(e.target.value)}
              ></input>
            </div>
            <div className="clientoptions">
              <input
                type="url"
                value={clientWebLink}
                placeholder="Website"
                onChange={(e) => setClientWebLink(e.target.value)}
              ></input>
            </div>
            <div className="clientoptions">
              <textarea
                type="text"
                value={clientNotes}
                placeholder="Write notes"
                onChange={(e) => setClientNotes(e.target.value)}
              ></textarea>
            </div>
            <div className="clientbtns">
              {clientmodalMode === "edit" && (
                <RetroButton onClick={handleDeleteClient}>Delete</RetroButton>
              )}
              <RetroButton onClick={handleSaveClient}>
                {clientmodalMode === "edit" ? "Update" : "Save"}
              </RetroButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
