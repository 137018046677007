import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import LoadingPage from "../retro/loading-page/loading-page.component";

import SignIn from "../../pages/sign-in/sign-in.component.class";

function ProtectedRoutes({ isAuth, element: Element, ...rest }) {
  const [fakeAuth, setFakeAuth] = useState(true);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isLoading = useSelector((state) => state.history.isLoading);
  const [isPending, setIsPending] = useState(true);
  const location = useLocation();

  // useEffect(() => {
  //   if (!currentUser) {
  //     setIsPending(true);
  //     const timeout = setTimeout(() => {
  //       setIsPending(false);
  //     }, 501);
  //     clearTimeout(timeout);
  //   }
  // }, [currentUser]);

  if (currentUser) {
    return <Outlet />;
  } else if (!isLoading) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // return currentUser ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/" state={{ from: location }} replace />
  // );

  // <Outlet />
  // <SignIn />
  // <Navigate to="/signin" state={{ from: location }} replace />

  // <Routes>
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       if (isAuth) {
  //         return <Component />;
  //       } else {
  //         return (
  //           <Navigate
  //             to={{ pathname: "/signin", state: { from: props.location } }}
  //           />
  //         );
  //       }
  //     }}
  //   />
  // </Routes>
}

export default ProtectedRoutes;
