import React from "react";
import { auth } from "../../firebase/firebase.utils";

import RetroButton from "../retro/button/retro-button.component";
import Input from "../retro/input/input.component";

import "./reset-password.styles.scss";

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      resetEmail: "",
      successMessage: "",
    };
    this.resetAccountPassword = this.resetAccountPassword.bind(this);
  }

  resetAccountPassword() {
    const { resetEmail } = this.state;
    auth
      .sendPasswordResetEmail(resetEmail)
      .then(() => {
        // Password reset email sent!
        this.setState({
          successMessage:
            "Email successfully sent! Please check your email to reset your password",
        });
      })
      .catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        this.setState({ errorMessage: errorMessage });
      });
  }

  render() {
    return (
      <div className="resetpasword">
        <h2>Reset Password</h2>
        <div onChange={(e) => this.setState({ resetEmail: e.target.value })}>
          <p>Type your email and hit the send button.</p>
          <Input placeholder="email" type="email" />
        </div>
        <RetroButton
          onClick={() => this.resetAccountPassword()}
          style={{ width: "50%" }}
        >
          Send
        </RetroButton>
        {/* <hr /> */}
        <div className="resetpasword__error">
          <p>{this.state.errorMessage}</p>
        </div>
        <div className="resetpasword__success">
          <p>{this.state.successMessage}</p>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
