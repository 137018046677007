import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/flatpickr.css";

import { RRule } from "rrule";

import { Picker } from "emoji-mart";

import Tooltip from "../retro/tooltip/tooltip.component";

import RetroButton from "../retro/button/retro-button.component";
import Avatar from "../retro/avatar/avatar.component";
import Message from "../message/message.component";
import Subtasks from "../dnd/subtasks/subtasks.component";
import Priority from "../modules/priority/priority.component";
import Assign from "../modules/assign/assign-component";
import CreatedBy from "../modules/created-by/created-by.component";
import CreatedDate from "../modules/created-date/created-date.component";
import DaysLeft from "../modules/days-left/days-left.component";

import Duration from "../duration/duration.component";
import Recurring from "../recurring/recurring.component";

import FocusTask from "../focus/focus-task.component";

import {
  BsPlusLg,
  BsArrowRepeat,
  BsArrowsExpand,
  BsArrowsCollapse,
  BsEmojiSmileUpsideDown,
  BsTrash,
} from "react-icons/bs";
import { FiSquare, FiCheckSquare } from "react-icons/fi";

import {
  db,
  fieldValue,
  updateTaskName,
  updateTaskDone,
  updateTaskDescription,
  updateTaskAllDay,
  updateTaskDuration,
  updateTaskRrule,
  updateTaskMilestone,
  updateTaskStart,
  updateTaskEnd,
  updateTaskProgress,
  createCommentToTask,
  deleteTask,
  // personCommentNotify,
} from "../../firebase/firebase.utils";

import InvitetoPremium from "../marketing/invite-to-premium.component";

import moment from "moment";

import "./task-detail.styles.scss";
import ProgressBar from "../progress-bar/progress-bar.component";
import EmojiPicker from "../emoji/emojiPicker";

const TaskDetail = ({
  task,
  event: eventInitialData,
  onSaveClick,
  onDeleteClick,
  onCancelClick,
  station,
  stationTasks,
  mode,
}) => {
  const [event, setEvent] = useState({
    // ...DEFAULT_EVENT,
    ...eventInitialData,
  });

  const users = useSelector((state) => state.user.users);
  const currentUser = useSelector((state) => state.user.currentUser);
  const spaceData = useSelector((state) => state.space.spaceData);
  const assignedTasks = useSelector((state) => state.tasks.tasks);
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const navigate = useNavigate();
  const { wspaceid, projectid, taskId } = useParams();

  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const [showInvitation, setShowInvitation] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const commenttextareaRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();

  const [emojiOpen, setEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState();

  const [reaction, setReaction] = useState();

  const [currentTask, setCurrentTask] = useState(null);

  const [refrrule, setRefRRule] = useState(null);
  // const [initialRRule, setInitialRRule] = useState(null);
  const [initialRRule, setInitialRRule] = useState(
    new RRule({ freq: RRule.DAILY, interval: 1 })
  );
  const [modifiedRRule, setModifiedRRule] = useState(null);

  const [rruleString, setRruleString] = useState(null);

  useEffect(() => {
    setRruleString(modifiedRRule?.toString());
  }, [modifiedRRule]);

  //AUTOSAVE RRULE
  useEffect(() => {
    // setRruleString(modifiedRRule.toString());

    const timer = setTimeout(() => {
      if (rruleString !== refrrule) {
        if (rruleString) {
          updateTaskRrule(
            currentTask.fromSpaceId,
            currentTask.fromStationId,
            currentUser.uid,
            taskId,
            rruleString
          );
          console.log("triggered rrule save");
        }
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [rruleString]);

  // const [mode, setMode] = useState("edit");

  useMemo(() => {
    // if (wsspaceid) {
    // const currentTaskRef = stationTasks?.filter((t) => t.id === taskId);
    // setCurrentTask(currentTaskRef[0]);
    // }
    // if (location.pathname.split("/")[2] === "task") {
    //   const currentTaskRef = assignedTasks?.filter((t) => t.id === taskId);
    //   setCurrentTask(currentTaskRef[0]);
    // } else {
    //   const currentTaskRef = stationTasks?.filter((t) => t.id === taskId);
    //   setCurrentTask(currentTaskRef[0]);
    // }

    //
    // if (stationTasks[0].fromStationId === currentStationId) {
    //   const currentTaskRef = stationTasks?.find((t) => t.id === taskId);
    //   if (currentTaskRef === null) {
    //     const currentTaskRefNew = assignedTasks?.find((t) => t.id === taskId);
    //     setCurrentTask(currentTaskRefNew);
    //   } else {
    //     setCurrentTask(currentTaskRef);
    //   }
    // } else {
    //   const currentTaskRef = assignedTasks?.find((t) => t.id === taskId);
    //   setCurrentTask(currentTaskRef);
    // }

    if (projectid) {
      const currentTaskRef = stationTasks?.find((t) => t.id === taskId);
      setCurrentTask(currentTaskRef);
    } else {
      const currentTaskRef = assignedTasks?.find((t) => t.id === taskId);
      setCurrentTask(currentTaskRef);
    }

    // if (location.pathname.split("/")[2] === "task") {
    //   const currentTaskRef = assignedTasks?.filter((t) => t.id === taskId);
    //   setCurrentTask(currentTaskRef[0]);
    // } else {
    //   const currentTaskRef = stationTasks?.filter((t) => t.id === taskId);
    //   setCurrentTask(currentTaskRef[0]);
    // }
  }, [assignedTasks, stationTasks]);

  // const findCurrentTask = () => {
  //   const currentTaskRef = stationTasks?.filter((t) => t.id === taskId);
  //   setCurrentTask(currentTaskRef[0]);
  // };

  useEffect(() => {
    setState(station);
    // findCurrentTask();
  }, [station, stationTasks]);

  // useCallback(() => {
  //   setMode("edit");
  // }, [currentTask]);

  const [id, setID] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [duration, setDuration] = useState();
  const [showduration, setShowDuration] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [allday, setAllday] = useState(false);
  const [alldayinitial, setAlldayInitial] = useState(false);
  const [showtime, setShowTime] = useState();
  const [taskdone, setTaskDone] = useState(false);
  const [phase, setPhase] = useState();
  const [progress, setProgress] = useState();
  const [type, setType] = useState();

  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");

  const [reactionpickerOpen, setReactionPickerOpen] = useState(false);
  const [reactionmessageref, setReactionMessageRef] = useState();

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [projectOptions, setProjectOptions] = useState(null);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [phaseOptions, setPhaseOptions] = useState(null);

  const [selectedPhaseId, setSelectedPhaseId] = useState(null);

  const setSelectedTask = () => {
    if (currentTask) {
      setID(currentTask.id);
      setTitle(currentTask.title);
      titleRef.current = currentTask.title;
      setDescription(currentTask.description);
      descriptionRef.current = currentTask.description;
      setStartDate(currentTask.startdate?.toDate());
      setEndDate(currentTask.enddate?.toDate());
      setDuration(currentTask.duration);
      setTaskDone(currentTask.done);
      setAllday(currentTask.allDay);
      setPhase(currentTask.phase);
      setProgress(currentTask.progress);
      setType(currentTask.type);
      setRefRRule(currentTask.rrule);
      if (currentTask.rrule !== null) {
        setInitialRRule(RRule.fromString(currentTask.rrule));
        // setModifiedRRule(RRule.fromString(currentTask.rrule));
      }
      // setRefRRule(currentTask.rrule);
      setSelectedWorkspaceId(currentTask.fromSpaceId);
    }
  };

  useEffect(() => {
    setSelectedTask();
    if (currentTask?.fromSpaceId === `${currentUser.uid}-Inbox`) {
      setMore(false);
    }
  }, [currentTask]);

  const handleUpdateEvent = (prop, value) => {
    setEvent((event) => ({ ...event, [prop]: value }));
  };

  const [more, setMore] = useState(true);
  const [modalsidemode, setModalSideMode] = useState(false);
  const [repeat, setRepeat] = useState(false);

  const handleSetRepeat = (e) => {
    e.preventDefault();
    setRepeat(!repeat);
  };
  const handleSetMore = (e) => {
    e.preventDefault();
    setMore(!more);
  };

  function showMoreOptions() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const handleSaveEvent = async (e) => {
    e.preventDefault();
    if (mode === "add") {
      const newTaskAdded = await db
        .collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("tasks")
        .add({
          title: title,
          description: "",
          done: false,
          startdate: startDate,
          enddate: endDate,
          duration: "",
          allDay: alldayinitial,
          createdAt: fieldValue.serverTimestamp(),
          createdBy: currentUser.uid,
          fromSpaceId: currentSpaceId,
          fromStationId: currentStationId,
          assign: null,
          phase: phase,
          priority: [
            { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
            { name: "High", active: false, color: "rgb(253, 171, 61)" },
            { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
            { name: "Low", active: false, color: "rgb(65, 163, 0)" },
          ],
          progress: 0,
          rrule: modifiedRRule,
          subtasks: [],
          type: type,
        });
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("tasks")
        .doc(newTaskAdded.id)
        .update({ id: newTaskAdded.id });
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("phases")
        .doc(phase)
        .update({
          taskIds: fieldValue.arrayUnion(newTaskAdded.id),
        });
    } else {
    }
    // onCancelClick();
  };

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       event.preventDefault();
  //       // callMyFunction();
  //       handleSaveEvent();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  // AUTOSAVE;
  useEffect(() => {
    if (titleRef.current !== title) {
      const timer = setTimeout(() => {
        updateTaskName(
          currentTask.fromSpaceId,
          currentTask.fromStationId,
          currentUser.uid,
          taskId,
          title
        );
        console.log("trigged title save");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [title]);

  useEffect(() => {
    if (descriptionRef.current !== description) {
      const timer = setTimeout(() => {
        updateTaskDescription(
          currentTask.fromSpaceId,
          currentTask.fromStationId,
          currentUser.uid,
          taskId,
          description
        );
        console.log("trigged description save");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [description, currentTask]);

  // const handleUpdateTaskName = (e) => {
  //   e.preventDefault();
  //   updateTaskName(
  //     currentTask.fromSpaceId,
  //     currentTask.fromStationId,
  //     currentUser.uid,
  //     taskId,
  //     title
  //   );
  // };

  const handleCheck = (val) => {
    setTaskDone(!taskdone);
    document.getElementById("done_tone").play();
    updateTaskDone(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      val
    );
  };

  // const handleUpdateTaskDescription = (e) => {
  //   e.preventDefault();
  //   updateTaskDescription(
  //     currentTask.fromSpaceId,
  //     currentTask.fromStationId,
  //     currentUser.uid,
  //     taskId,
  //     description
  //   );
  // };

  const handleUpdateTaskAllDay = (e) => {
    if (e.target.checked === true) {
      setShowTime(true);
      setAllday(true);
    } else {
      setShowTime(false);
      setAllday(false);
    }
    updateTaskAllDay(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      e.target.checked
    );
  };

  const handleUpdateTaskDuration = (duration) => {
    setDuration(duration);
    updateTaskDuration(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      duration
    );
  };

  const onMilestoneChange = (e) => {
    if (e.target.checked === true) {
      setType("milestone");
      const taskType = "milestone";
      updateTaskMilestone(
        currentTask.fromSpaceId,
        currentTask.fromStationId,
        currentUser.uid,
        taskId,
        taskType
      );
    } else {
      setType("task");
      const taskType = "task";
      updateTaskMilestone(
        currentTask.fromSpaceId,
        currentTask.fromStationId,
        currentUser.uid,
        taskId,
        taskType
      );
    }
  };

  const handleUpdateTaskStart = (date) => {
    setStartDate(date);
    updateTaskStart(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      date
    );
  };
  const handleUpdateTaskEnd = (date) => {
    setEndDate(date);
    updateTaskEnd(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      date
    );
  };
  const handleUpdateTaskProgress = (progressval) => {
    setProgress(progressval);
    updateTaskProgress(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      progressval
    );
  };

  const handleUpdateTask = (e) => {
    e.preventDefault();
    // console.log("e.fromSpaceId is:", event.fromSpaceId);
    // console.log("userId is:", `${currentUser.uid}-Inbox`);
    if (event.fromSpaceId === `${currentUser.uid}-Inbox`) {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("tasks")
        .doc(event.id)
        .update({
          title: title,
          description: description,
          // done: taskdone,
          startdate: startDate,
          enddate: endDate,
          // duration: duration,
          allDay: allday,
          type: type,
          // progress: progress,
          // rrule: refrrule,
        });
    } else {
      db.collection("space")
        .doc(event.fromSpaceId)
        .collection("stations")
        .doc(event.fromStationId)
        .collection("tasks")
        .doc(id)
        .update({
          title: title,
          description: description,
          done: taskdone,
          startdate: startDate,
          enddate: endDate,
          duration: duration,
          allDay: allday,
          type: type,
          progress: progress,
        });
      if (refrrule) {
        db.collection("space")
          .doc(event.fromSpaceId)
          .collection("stations")
          .doc(event.fromStationId)
          .collection("tasks")
          .doc(id)
          .update({
            rrule: refrrule,
          });
      }
    }
    // onCancelClick();
  };

  const handleDeleteTask = (e) => {
    e.preventDefault();
    deleteTask(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentTask.phase,
      currentUser.uid,
      currentTask
    );
    // onCancelClick();
    navigate(-1);
  };

  const [sttaken, setStTaken] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      // .doc(currentStationId)
      // .collection("tasks")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
            emojicon: doc.get("emojicon"),
          };
        });
        setStTaken([...array]);
      });

    return () => {
      unsub();
    };
  }, []);

  const [stphase, setStPhase] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("phases")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
          };
        });
        setStPhase([...array]);
      });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    //mounts
    if (currentTask?.fromSpaceId !== `${currentUser.uid}-Inbox`) {
      const unsub = db
        .collection("space")
        .doc(currentTask?.fromSpaceId)
        .collection("stations")
        .doc(currentTask?.fromStationId)
        .collection("tasks")
        .doc(taskId)
        .collection("comments")
        .orderBy("created", "desc")
        .limit(15)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.exists) {
            const array = querySnapshot.docs.map((msg) => {
              return {
                id: msg.id,
                created: msg.get("created"),
                from: msg.get("from"),
                fromTaskId: msg.get("fromTaskId"),
                message: msg.get("message"),
                reaction: msg.get("reaction"),
              };
            });
            setComments([...array]);
          }
        });
      return () => {
        unsub();
      };
    }
  }, [id]);

  const [selectedstation, setSelectedStation] = useState("");
  const [currentphase, setCurrentPhase] = useState("");
  const [selectedphase, setSelectedPhase] = useState("");

  const handleStationChange = (e) => {
    const value = e.target.value;
    setSelectedStation(value);
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(selectedstation)
      .collection("tasks")
      .add({
        title: title,
        startdate: startDate,
        enddate: endDate,
        // starttime: event.startTime,
        duration: duration,
        allDay: allday,
      });

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(selectedstation)
      .collection("phases")
      .doc(selectedphase)
      .update({
        taskIds: fieldValue.arrayUnion(id),
      });

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .delete();

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("phases")
      .doc(currentphase)
      .update({
        taskIds: fieldValue.arrayRemove(id),
      });
  };
  const handlePhaseChange = (e) => {
    setPhase(e.target.value);
    // setSelectedPhase(value);
    // db.collection("space")
    //   .doc(currentSpaceId)
    //   .collection("stations")
    //   .doc(selectedstation)
    //   .collection("tasks")
    //   .add({
    //     title: title,
    //     startdate: startDate,
    //     enddate: endDate,
    //     // starttime: event.startTime,
    //     duration: duration,
    //     allDay: allday,
    //   });

    // db.collection("space")
    //   .doc(currentSpaceId)
    //   .collection("stations")
    //   .doc(currentStationId)
    //   .collection("tasks")
    //   .doc(id)
    //   .delete();
  };

  const taskrefinfo = {
    fromSpaceId: event.fromSpaceId,
    fromStationId: event.fromStationId,
    id: event.id,
    done: !event.done,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!comment) {
    //   alert("no message");
    //   return;
    // }
    if (comment) {
      createCommentToTask(
        currentTask.fromSpaceId,
        currentTask.fromStationId,
        comment,
        currentUser.uid,
        taskId,
        currentUser.userName
      );
      commenttextareaRef.current.value = "";
      setComment("");
    }
  };

  const handlesetEmojiOpen = (e) => {
    e.preventDefault();
    setEmojiOpen(!emojiOpen);
  };

  const handlesetReactionPickerOpen = (e) => {
    e.preventDefault();
    setReactionPickerOpen(!reactionpickerOpen);
  };

  const onSelectEmoji = (emojiObject) => {
    commenttextareaRef.current.focus();
    setEmoji(emojiObject);
    const emoji = emojiObject.native;
    setComment(comment ? comment + emoji : emoji);
    setEmojiOpen(!emojiOpen);
  };

  const handleDeleteComment = (e, msg) => {
    e.preventDefault();
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .collection("comments")
      .doc(msg.id)
      .delete();
  };

  const onSelectReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .collection("comments")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const handleExitBtnClicked = (e) => {
    e.preventDefault();
    if (mode === "edit") {
      if (titleRef.current !== title) {
        updateTaskName(
          currentTask.fromSpaceId,
          currentTask.fromStationId,
          currentUser.uid,
          taskId,
          title
        );
      }
    }
    navigate(-1);
  };

  const handleWorkspaceSelect = (e) => {
    setSelectedWorkspaceId(e.target.value);
    db.collection("space")
      .doc(e.target.value)
      .collection("stations")
      // .doc(currentStationId)
      // .collection("tasks")
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
            emojicon: doc.get("emojicon"),
          };
        });
        setProjectOptions([...array]);
        // handleProjectSelect([...array][0].id);
      });
  };

  const handleCancelRecurring = () => {
    updateTaskRrule(
      currentTask.fromSpaceId,
      currentTask.fromStationId,
      currentUser.uid,
      taskId,
      null
    );
  };

  return (
    <form key={taskId} className="TaskModal" onSubmit={handleSaveEvent}>
      <div onClick={handleExitBtnClicked} className="overlay"></div>
      <motion.form
        initial={{ x: "-230px", y: 0, opacity: 0, scale: 0.6 }}
        animate={{ x: "0px", y: 0, rotate: 0, opacity: 1, scale: 1 }}
        transition={{ type: "ease", duration: 0.3, delay: 0.15 }}
        exit={{ x: "60%", opacity: 0 }}
        className={!more ? "modal-content" : "modal-content-full"}
      >
        <div className="TaskModal__Main">
          <div className="TaskModal__Row1">
            <h4>{mode === "add" ? "Add New" : "Edit"}</h4>
            <select className="tm_select" onChange={handleWorkspaceSelect}>
              <option
                // value={station.id}
                // onChange={handleStationChange}
                value={`${currentUser.uid}-Inbox`}
                selected={selectedWorkspaceId === `${currentUser.uid}-Inbox`}
                className="tm_option"
              >
                Inbox
              </option>
              {spaceData?.map((space) => {
                return (
                  <option
                    key={space.spaceId}
                    value={space.spaceId}
                    selected={selectedWorkspaceId === space.spaceId}
                    className="tm_option"
                  >
                    {space.name}
                  </option>
                );
              })}
            </select>
            <button className="exit__button" onClick={handleExitBtnClicked}>
              <BsPlusLg size="1.7em" />
            </button>
          </div>
          {/* <button
          className="sidemode__button"
          onClick={() => setModalSideMode(!modalsidemode)}
        >
          <BsReverseLayoutSidebarReverse size="1.2em" />
        </button> */}

          <div className="TaskModal__Row">
            <div className="tm_breadcrumb">
              <select className="tm_select">
                {sttaken.map((station) => (
                  <option
                    key={id}
                    value={station.id}
                    onChange={handleStationChange}
                    className="tm_option"
                  >
                    {station.emojicon}{" "}
                    {station.name}
                  </option>
                ))}
              </select>
              <select
                className="tm_select"
                value={phase}
                onChange={handlePhaseChange}
                selected
                defaultValue={[1]}
                // defaultValue={phase}
              >
                {stphase.map((phase) => (
                  <option key={phase.id}>{phase.name}</option>
                ))}
              </select>
            </div>
            {/* <div className="TaskModal__module">
              <FocusTask
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                task={event}
                title={title}
              />
            </div> */}
            <div className="TaskModal__module">
              <Assign task={currentTask} />
            </div>
          </div>

          <div className="TaskModal__Row2">
            {mode === "edit" && (
              <div
                className={
                  taskdone ? "task__modal-done" : "task__modal-notdone"
                }
              >
                <i>
                  {taskdone ? (
                    <FiCheckSquare onClick={() => handleCheck(false)} />
                  ) : (
                    <FiSquare onClick={() => handleCheck(true)} />
                  )}
                </i>
                <audio
                  id="done_tone"
                  src="/media/mixkit-cool-interface-click-tone-2568.wav"
                ></audio>
              </div>
            )}
            <div className="modal_info-block">
              <div className="modal_task_name">
                {mode === "add" ? (
                  <textarea
                    autoFocus
                    className="modal_task_name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Task / Event Name..."
                  ></textarea>
                ) : (
                  <textarea
                    className="modal_task_name"
                    value={title}
                    ref={titleRef}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Task / Event Name..."
                  ></textarea>
                )}
                {/* <button onClick={(e) => handleUpdateTaskName(e)}>
                  CHANGE NAME
                </button> */}
              </div>
              <div className="tm__description-priority">
                <div className="tm__description-box">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Add description"
                  />
                  {/* <button onClick={(e) => handleUpdateTaskDescription(e)}>
                    Save desc
                  </button> */}
                </div>
                <div className="TaskModal__module-priority">
                  <Tooltip text="Priority" />
                  {currentTask && <Priority task={currentTask} />}
                </div>
              </div>
            </div>
          </div>
          <div className="TaskModal__Row3">
            <div className="tm__allday">
              <input
                type="checkbox"
                value={allday}
                checked={allday}
                onChange={handleUpdateTaskAllDay}
              />
              <label className={!allday && "tm__alldaytext"}>All day</label>
            </div>
            {showInvitation && (
              <InvitetoPremium
                setShowInvitation={setShowInvitation}
                featureName={"Duration Estimates"}
                featureDesc={
                  "set duration estimates, automatically generate start/end time upon dragging a task on a time-block of the calendar"
                }
                featureImg={
                  "https://images.unsplash.com/photo-1555255675-1d2d8db2d191?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                }
                currentUser={currentUser}
              />
            )}
            <Duration
              event={currentTask}
              open={showduration}
              duration={duration}
              setDuration={setDuration}
              setShowInvitation={setShowInvitation}
              showInvitation={showInvitation}
              currentUser={currentUser}
              handleUpdateTaskDuration={handleUpdateTaskDuration}
              // onClick={currentUser.plan == "Free" ? setShowInvitation(true) : setShowInvitation(false)}
            />
          </div>

          {/* {!repeat && ( */}
          <div className="TaskModal__Row">
            {!showtime ? (
              <div className="twocolumns">
                <div>
                  <label>start</label>
                  <DatePicker
                    popperProps={{ strategy: "fixed" }}
                    wrapperClassName="datepicker"
                    isClearable
                    placeholderText="Select a start time"
                    value={event.startDate}
                    showTimeSelect
                    // startDate="start"
                    timeFormat="p"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMM d, yy | hh:mm aa"
                    selected={startDate}
                    onChange={(date) => handleUpdateTaskStart(date)}
                    // note: this onChange is not needed, because it saves to db when it is not active as well
                    // minDate={moment().toDate()}
                    todayButton="today"
                  />
                </div>
                <div>
                  <label>end</label>
                  <DatePicker
                    popperProps={{ strategy: "fixed" }}
                    popperPlacement="left-end"
                    popperClassName="date-picker-reports"
                    wrapperClassName="datepicker"
                    isClearable
                    placeholderText="Select end or deadline"
                    // value={event.endDate}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="MMM d, yy | hh:mm aa"
                    selected={endDate}
                    onChange={(date) => handleUpdateTaskEnd(date)}
                    todayButton="today"
                  />
                </div>
              </div>
            ) : (
              <div className="twocolumnsnarrow">
                <div>
                  <label>start</label>
                  <DatePicker
                    popperProps={{ strategy: "fixed" }}
                    wrapperClassName="datepicker"
                    isClearable
                    placeholderText="Select a start date"
                    value={event.startDate}
                    // showTimeSelect
                    // startDate="start"
                    timeFormat="p"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy"
                    selected={startDate}
                    onChange={(date) => handleUpdateTaskStart(date)}
                    // note: this onChange is not needed, because it saves to db when it is not active as well
                    // minDate={moment().toDate()}
                    todayButton="today"
                  />
                </div>
                <div>
                  <label>end</label>
                  <DatePicker
                    popperProps={{ strategy: "fixed" }}
                    wrapperClassName="datepicker"
                    isClearable
                    placeholderText="Select end or deadline"
                    // value={event.endDate}
                    // showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy"
                    selected={endDate}
                    onChange={(date) => handleUpdateTaskEnd(date)}
                  />
                </div>
              </div>
            )}
          </div>
          {/* )} */}

          <div className="TaskModal__Row4">
            {!repeat && (
              <div className="lt__subtasks">
                {currentTask && (
                  <Subtasks task={currentTask} open opacity="opacityTrue" />
                )}
              </div>
            )}
            {repeat && (
              <button
                className="repeat_cancel-btn"
                onClick={handleCancelRecurring}
              >
                Cancel Repeat
              </button>
            )}
            <button className="repeat_icon" onClick={handleSetRepeat}>
              <BsArrowRepeat size="1.5em" />
              <Tooltip text="Repeat" />
              {/* Does not repeat */}
            </button>
          </div>

          {repeat && (
            <div className="TaskModal__Row">
              <Recurring
                initialRRule={initialRRule}
                modifiedRRule={modifiedRRule}
                setModifiedRRule={setModifiedRRule}
                rruleString={rruleString}
                startDateRef={startDate}
              />
            </div>
          )}

          <div className="Buttons__Row">
            <div className="save__button">
              {mode === "add" && (
                <div className="tm__button">
                  <RetroButton
                    type="submit"
                    onClick={handleSaveEvent}
                    disabled={repeat ? true : false}
                  >
                    Save
                  </RetroButton>
                </div>
              )}
              {/* {mode === "edit" && (
                <div className="tm__button">
                  <RetroButton
                    type="submit"
                    onClick={handleUpdateTask}
                    disabled={repeat ? true : false}
                  >
                    Update
                  </RetroButton>
                </div>
              )} */}
              {mode === "edit" && (
                <div className="tm__delete">
                  <RetroButton
                    onClick={handleDeleteTask}
                    disabled={repeat ? true : false}
                  >
                    <BsTrash size="1.5em" />
                  </RetroButton>
                </div>
              )}
            </div>
            {currentTask?.fromSpaceId !== `${currentUser.uid}-Inbox` && (
              <>
                {more ? (
                  <button className="more_icon" onClick={handleSetMore}>
                    <p>less...</p>
                    {/* <BsArrowsCollapse size="2em" /> */}
                    <Tooltip text="show less..." />
                  </button>
                ) : (
                  <button className="more_icon" onClick={handleSetMore}>
                    <p>more...</p>
                    {/* <BsArrowsExpand size="2em" /> */}
                    <Tooltip text="show more..." />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {currentTask?.fromSpaceId !== `${currentUser.uid}-Inbox` && (
          <>
            {more & (mode === "edit") ? (
              <div
                className={more ? "tm__extra-column" : null}
                style={{ transition: "all 1s ease" }}
              >
                <div className="TaskModal__ModulesRow">
                  <ProgressBar
                    progress={progress}
                    setProgress={setProgress}
                    key="{idx}"
                    bgcolor="#3b3b3b"
                    completed={progress}
                    handleUpdateTaskProgress={handleUpdateTaskProgress}
                  />
                  <div className="tm__milestone">
                    <input
                      type="checkbox"
                      value={type}
                      checked={type == "milestone" ? true : false}
                      onChange={onMilestoneChange}
                    />
                    <label className={!type && "tm__milestonetext"}>
                      Milestone
                    </label>
                  </div>
                  {mode === "edit" && currentTask && (
                    <>
                      <div className="TaskModal__module">
                        <label>Created by</label>
                        <CreatedBy task={currentTask} />
                      </div>
                      <div className="TaskModal__module">
                        <label>Created at</label>
                        <CreatedDate task={currentTask} />
                      </div>
                      <div className="TaskModal__module">
                        <label>Day</label>
                        <DaysLeft task={currentTask} />
                      </div>
                    </>
                  )}
                </div>
                {/* <input placeholder="Dependency"></input> */}
                <div className="tm__writeUpdate-msgs">
                  {comments.map((msg) => {
                    return (
                      <div
                        className={
                          msg.from === currentUser?.uid
                            ? "tm__messageLine-Self"
                            : "tm__messageLine"
                        }
                      >
                        <div className="tm__messagewithReaction">
                          <Message key={msg.id} msg={msg} />
                          <button
                            className={
                              msg.from === currentUser?.uid
                                ? "tm__messageReaction-Self"
                                : "tm__messageReaction"
                            }
                          >
                            {msg.reaction}
                          </button>
                        </div>
                        <button
                          className="ch__EmojiPick"
                          onClick={(e) => handlesetReactionPickerOpen(e)}
                        >
                          <button
                            className="ch__EmojiPickIcon"
                            onClick={() => setReactionMessageRef(msg.id)}
                          >
                            <BsEmojiSmileUpsideDown />
                          </button>

                          {/* <MdOutlineEmojiFoodBeverage /> */}
                        </button>
                        <button
                          className="ch__MessageDelete"
                          onClick={(e) => handleDeleteComment(e, msg)}
                        >
                          <BsTrash />
                        </button>
                        <div className="tm__ReactionPickerOn">
                          {reactionpickerOpen && (
                            <EmojiPicker
                              onEmojiSelect={onSelectReaction}
                              onClickOutside={() =>
                                setReactionPickerOpen(false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="tm__writeUpdate-message">
                  <form>
                    <textarea
                      onChange={(e) => setComment(e.target.value)}
                      ref={commenttextareaRef}
                      placeholder="Write a comment"
                      value={comment}
                    />
                  </form>
                  <div className="tm__writeUpdate-buttongroup">
                    <div className="tm__writeUpdate-buttongroup-sub">
                      <div className="tm__writeUpdate-avatar">
                        <Avatar src={currentUser.imageUrl} />
                      </div>
                      <div className="tm__EmojiIcon">
                        <button
                          className="tm__EmojiPick"
                          onClick={(e) => handlesetEmojiOpen(e)}
                        >
                          <button>
                            <BsEmojiSmileUpsideDown size="1.2em" />
                          </button>
                        </button>
                        <div className="tm__PickerOn">
                          {emojiOpen && (
                            <EmojiPicker
                              onEmojiSelect={onSelectEmoji}
                              onClickOutside={() => setEmojiOpen(false)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="tm__writeUpdate-btn" onClick={handleSubmit}>
                      <RetroButton>
                        <a>Send</a>
                      </RetroButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </motion.form>
    </form>
  );
};

export default TaskDetail;
