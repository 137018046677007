import React from "react";
import { motion } from "framer-motion";

const Projects = [
  {
    title: "Personal Dashboard",
    color: "green",
    img: "/homepage/home_dashboard.jpg",
  },
  {
    title: "Workspace Dashboard",
    color: "blue",
    img: "/homepage/workspace_dashboard.jpg",
  },
  {
    title: "Project Page",
    color: "orange",
    img: "/homepage/project_page.jpg",
  },
];
const CARD_OFFSET = 10;
const SCALE_FACTOR = 0.06;
export default function CardStack() {
  const [cards, setCards] = React.useState(Projects);
  const [currentCard, setCurrentCard] = React.useState(1);
  const moveToEnd = (from) => {
    if (currentCard !== cards.length) {
      setCurrentCard(currentCard + 1);
    } else {
      setCurrentCard(1);
    }

    console.log("Cards before: ", cards);
    const newOrder = cards;
    newOrder.push(newOrder.shift());
    setCards(newOrder);
  };

  return (
    <div style={wrapperWrapper}>
      <div style={wrapperStyle}>
        <ul style={cardWrapStyle}>
          {cards.map((item, index) => {
            const canDrag = index === 0;

            return (
              <motion.li
                key={item.color}
                style={{
                  ...cardStyle,
                  //   backgroundColor: item.color,
                  backgroundColor: "black",
                  cursor: canDrag ? "grab" : "auto",
                }}
                animate={{
                  top: index * CARD_OFFSET * -3.2,
                  left: index * CARD_OFFSET * 2.8,
                  scale: 1 - index * SCALE_FACTOR,
                  zIndex: Projects.length - index,
                }}
                drag={canDrag ? "x" : false}
                dragConstraints={{
                  left: 50,
                  right: 50,
                }}
                onDragEnd={() => moveToEnd(index)}
                onClick={() => moveToEnd(index)}
              >
                <h4
                  style={{
                    position: "absolute",
                    fontSize: "18px",
                    background: "white",
                    borderRadius: "4px",
                    padding: "5px",
                    color: "#3B3B3B",
                    bottom: "5%",
                    right: "5%",
                    boxShadow: "0px 0px 12px 8px #a9a9a921",
                  }}
                >
                  {item.title}
                </h4>
                <img
                  alt="puppy"
                  src={item.img}
                  style={{
                    width: "inherit",
                    pointerEvents: "none",
                  }}
                />
                {/* <button
                  onClick={() => moveToEnd(index)}
                  style={{
                    position: "absolute",
                    bottom: "20%",
                  }}
                >
                  Next
                </button> */}
              </motion.li>
            );
          })}
        </ul>
      </div>
      {/* <h4
        style={{
          position: "absolute",
          width: "100px",
          textAlign: "center",
          bottom: "10%",
        }}
      >
        Project {currentCard} of {cards.length}
      </h4> */}
    </div>
  );
}

const wrapperWrapper = {
  fontFamily: "roboto",
  position: "relative",
};

const wrapperStyle = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

const cardWrapStyle = {
  position: "relative",
  width: "700px",
  height: "100%",
};

const cardStyle = {
  position: "absolute",
  width: "100%",
  height: "385px",
  borderRadius: "8px",
  transformOrigin: "top center",
  listStyle: "none",
  overflow: "hidden",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  boxShadow: "0 1px 7px 1px rgba(0, 0, 0, 0.35)",
  //   padding: "1px",
};
