import React from "react";
import "./faq.scss";

export default function FaQ({ Question, Answer }) {
  return (
    <div className="item__faq">
      <details key={Question} className="habitline">
        {Answer}
        <summary className="habitinfo">
          <h5>{Question}</h5>
        </summary>
      </details>
    </div>
  );
}
