import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

import Stations from "../../components/stations/stations.component";
import SharedWithMe from "../../components/stations/sharedwithme.component";

import { setOpen } from "../../redux/user/user.actions";

import { BsChevronRight, BsArrowBarLeft } from "react-icons/bs";

import { setOpenFb } from "../../firebase/firebase.utils";

import "./station.syles.scss";

const Station = () => {
  const currentUserUid = useSelector((state) => state.user.currentUser?.uid);
  const currentUserOrg = useSelector(
    (state) => state.user.currentUser?.organization
  );
  const spaceId = useSelector((state) => state.history.spaceId);
  const stationId = useSelector((state) => state.history.stationId);
  const open = useSelector((state) => state.user.currentUser?.open);
  const [x, setX] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [guestMode, setGuestMode] = useState(false);

  useEffect(() => {
    if (!spaceId) return;

    if (spaceId === "sharedwithme") {
      setGuestMode(true);
    }

    async function fetchData() {
      const docRef = await db.collection("space").doc(spaceId).get();
      const spaceData = docRef.data();
      if (!spaceData) return;
      // if (!spaceData.members.includes(currentUserUid)) {
      //   alert("u have no access");
      //   navigate("/");
      // }
      if (!spaceData.members.includes(currentUserUid)) {
        setGuestMode(true);
      } else {
        setGuestMode(false);
      }
    }
    fetchData();
  }, [spaceId, stationId]);

  useEffect(() => {
    if (open) {
      setX(0);
    } else {
      setX("-180px");
    }
  }, [open]);

  const handleMini = () => {
    dispatch(setOpen(!open));
    setOpenFb(currentUserUid, open);
  };

  return (
    <motion.div
      className="station"
      animate={{ marginLeft: x }}
      transition={{ ease: "easeOut" }}
    >
      <motion.div className="station__headermock">
        <Link to={``}>
          <img
            classname="antLogy__logo"
            src="/antlogy_logo.png"
            height="36px"
            alt=""
            style={{ objectFit: "cover" }}
          />
        </Link>
        {currentUserOrg === "Antlogy" && (
          <Link to="/webadmin">
            <button className="uniquelinkdockstation" />
          </Link>
        )}
      </motion.div>
      {/* <div>
        <motion.div
          className="breadcrumb__arrow1"
          animate={{ x: 15 ? "" : 15 }}
        >
          <BsChevronRight size="1.5em" strokeWidth="0.5" />
        </motion.div>
      </div>
      <motion.div
        className="breadcrumb__arrow2"
        animate={{ x: open ? "" : 15 }}
      >
        <BsChevronRight size="1.5em" strokeWidth="0.5" />
      </motion.div> */}
      <motion.div
        className={"station__hide"}
        animate={{ y: open ? 0 : -27, x: open ? 0 : 0, rotate: open ? 0 : 180 }}
        transition={{ times: [0, 0.1, 0.9, 1], duration: 0.3 }}
        onClick={() => handleMini()}
      >
        <div
          className={open ? "station__hideicon" : "station__hideicon__rotated"}
        >
          <BsArrowBarLeft size="1.5em" strokeWidth="0.1" />
        </div>
      </motion.div>
      {guestMode ? <SharedWithMe /> : <Stations />}
    </motion.div>
  );
};

export default Station;
