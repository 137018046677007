import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsEnvelopeAt,
  BsTwitter,
} from "react-icons/bs";

import "./blogPost.scss";

export default function SocialShareBtns({
  incrementFB,
  incrementTW,
  incrementLI,
  incrementEM,
}) {
  const [menuActive, setMenuActive] = useState(false);
  const handleToggleMenu = () => {
    setMenuActive((menuActive) => !menuActive);
  };

  const location = useLocation();
  const currentLink = location.pathname;

  const { slug } = useParams();

  const openFBLink = (e) => {
    incrementFB();
    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=#${currentLink}`,
      "_blank",
      "width=600,height=600"
    );
    if (newWindow) {
      newWindow.focus();
    }
  };

  const openTWLink = (e) => {
    incrementTW();
    const newWindow = window.open(
      `https://twitter.com/intent/tweet?url=${currentLink}&text=Here%20is%20an%20article%20from%20Antlogy%20that%20I%20think%20you%20may%20find%20useful`,
      "_blank",
      "width=600,height=600"
    );
    if (newWindow) {
      newWindow.focus();
    }
  };

  const openLILink = (e) => {
    incrementLI();
    const newWindow = window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${currentLink}`,
      "_blank",
      "width=600,height=600"
    );
    if (newWindow) {
      newWindow.focus();
    }
  };

  const openEMLink = (e) => {
    incrementEM();
    const newWindow = window.open(
      `mailto:?subject=A%20useful%20link%20from%20Antlogy:%20${slug}&body=${currentLink}`,
      "_blank",
      "width=600,height=600"
    );
    if (newWindow) {
      newWindow.focus();
    }
  };

  return (
    <div className="blogpost__socials">
      <button aria-label="Share on Facebook" onClick={openFBLink}>
        <BsFacebook size="2em" />
      </button>
      <button aria-label="Share on Twitter" onClick={openTWLink}>
        <BsTwitter />
      </button>
      <button aria-label="Share on LinkedIn" onClick={openLILink}>
        <BsLinkedin />
      </button>
      <button aria-label="Share via Email" onClick={openEMLink}>
        <BsEnvelopeAt />
      </button>
    </div>
  );
}
