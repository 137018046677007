import React from "react";
import "./resources.scss";
import FaQ from "../components/faq";

const FAQs = [
  {
    Question: "How do you protect my data?",
    Answer:
      "Your data is kept securely in special protected locations. Only members of your workspaces and projects can access them",
  },
  {
    Question: "What kind of privacy do you provide?",
    Answer:
      "We simply don't share your data with 3rd parties to make more money. The only emails/notifications you get will be from us and they can be set based on your preferences.",
  },
  {
    Question: "How do you charge multiple member teams?",
    Answer:
      "We kept our pricing modal simple. The price you see in the pricing section is for one user, you can always expand/shrink your teams.",
  },
  {
    Question: "Does your platform integrate with other software and tools?",
    Answer:
      "Because we think they are essential, we currently support adding google calendars and google drive folder views. But we plan to expand such integrations.",
  },
  {
    Question: "What if I want to get a refund?",
    Answer:
      "We believe in client satisfaction, if you are not happy with our software for any reason, we can give you a refund within 48h.",
  },
];

export default function SectionResources() {
  return (
    <section id="resources" className="sec__resources">
      <div className="sec__resources-heading">
        <h2>Resources</h2>
        <h3>FAQs</h3>
      </div>
      <div className="sec__faq">
        {FAQs.map((frequentlyaskeditem) => {
          const { Question, Answer } = frequentlyaskeditem;
          return <FaQ key={Question} Question={Question} Answer={Answer} />;
        })}
      </div>
    </section>
  );
}
