import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, setTimerOpen } from "../../firebase/firebase.utils";
import { motion } from "framer-motion";

import Avatar from "../../components/retro/avatar/avatar.component";
import SpaceFly from "../../components/space-fly/space-fly.component";
import HomeFly from "../../components/home-fly/home-fly.component";

import { setSpaceData, removeSpaceData } from "../../redux/space/space.actions";
import { setLoading } from "../../redux/history/history.actions";
import { setOpen } from "../../redux/user/user.actions";

import "./space.styles.scss";
import "../../theme.styles.scss";
import UserProfile from "../../components/user-profile/user-profile.component";

import FocusApp from "../../components/focus/focus.component";

import { BsSun, BsMoonStars, BsClockHistory } from "react-icons/bs";

import { Link } from "react-router-dom";

const Space = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  // const spaceId = useSelector((state) => state.history.spaceId);
  const open = useSelector((state) => state.user.currentUser.open);
  const dispatch = useDispatch();

  const [countdownVal, setCountdownVal] = useState("Hello World");
  const countdownRef = (timer) => {
    setCountdownVal(timer);
  };

  const [isOpen, SetIsOpen] = useState(false);
  const [x, setX] = useState(0);

  useEffect(() => {
    db.collection("space")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setSpaceData(shots));
        }
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 500);
      });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userDatat) => {
        const getOpen = userDatat.data().open;
        SetIsOpen(userDatat.data().timerOpen);
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }
      });
  }, []);

  const handleTimerOpen = () => {
    // dispatch(setOpen(!open));
    SetIsOpen(!isOpen);
    setTimerOpen(currentUser.uid, isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setX("55px");
    } else {
      setX("-300px");
    }
  }, [isOpen]);

  // const [theme, setTheme] = useState("light");
  // if (theme === "dark") {
  //   document.body.style.background = "#141517";
  //   // document.body.style.color = "white";
  //   // document.getElementsByClassName("list__column").background = "black";
  //   // document.querySelector('html').style.filter = 'invert(100%)'
  // } else {
  //   document.body.style.background = "none";
  //   // document.querySelector('html').style.filter = 'none'
  // }

  const body = document.body;

  const [theme, setTheme] = useState("light");
  if (theme === "dark") {
    body.classList.add("dark-mode");
  } else {
    body.classList.remove("dark-mode");
  }

  return (
    <div className="space">
        <HomeFly />
      <motion.div
        className="workspace"
        animate={{
          marginTop: open ? "0px" : "-20px",
          paddingTop: open ? "0px" : "20px",
        }}
      >
        {/* <div className="space__fly"> */}
        <SpaceFly />
        {/* </div> */}
        <div className="user__settings">
          <div className="station__headermockmobile">
            <Link to="/app">
              <img
                classname="antLogy__logo"
                src="/antlogy_logo.png"
                height="40px"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </Link>
          </div>
          <div className="timer__hide" onClick={handleTimerOpen}>
            {!isOpen && <div>{countdownVal}</div>}
            <BsClockHistory size="1.5em" />
          </div>
          <motion.div
            className="Focus__timer"
            animate={{ left: x }}
            transition={{ ease: "easeOut" }}
          >
            {/* {isOpen && (
              <div>
                <div> */}
            <FocusApp
              countdownRef={countdownRef}
              setCountdownVal={setCountdownVal}
              isOpen={isOpen}
              handleTimerOpen={handleTimerOpen}
              style={{
                display: isOpen ? "flex" : "none",
              }}
            />
            {/* </div>
              </div>
            )} */}
          </motion.div>
          <button
            className="themeToggle"
            onClick={() => setTheme(theme == "light" ? "dark" : "light")}
          >
            {theme == "light" && <BsSun size="1.5em" />}
            {theme == "dark" && <BsMoonStars size="1.5em" />}
            {/* <BsSun size="1.5em" /> */}
          </button>
          <div className="space__user">
            <UserProfile currentUser={currentUser} />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Space;
