import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe(
            "pk_test_51NMAExAZXjizkbt7MpgekW8cYBhJriRZ483GlVJGIN99Xtg7rnUMpzHhyYhOztQsmcfwKUBAXVjzIqaGPi17zzlX00cuhotxQK"
        );
    }
    return stripePromise;
}

export default initializeStripe;