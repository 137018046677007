import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import GoBackButton from "../ui-elements/gobackbutton";

import "./welcome-home.styles.scss";

const WelcomeHome = ({ numOfPrTasks }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentUserOrg = useSelector(
    (state) => state.user.currentUser?.organization
  );
  const { userName, assignedTasks } = currentUser;
  const [timeOfDay, setTimeOfDay] = useState(null);

  /* const name = userName.split(" ")[0]; */ // ne radi za ime jednog rijeci

  // function TexttoSpeech() {
  //   const handleClick = () => {
  //     const text = "Hello, this is a test text for text to speech feature";
  //     const value = new SpeechSynthesisUtterance(text);
  //     window.speechSynthesis.speak(value);
  //   };
  //   return <button onClick={handleClick}>Text to Speech</button>;
  // }

  const name = userName;
  let time = new Date();

  useEffect(() => {
    let h = time.getHours();

    if (h >= 0 && h <= 12) {
      setTimeOfDay("Good morning");
    }
    if (h > 12 && h <= 18) {
      setTimeOfDay("Good afternoon");
    }
    if (h > 18 && h <= 24) {
      setTimeOfDay("Good evening");
    }
  }, [time]);
  return (
    <div className="allHome">
      {/* <div className="homeIconLeft"></div> */}
      <Link to="/" className="homeIconAll">
        <img
          classname="antLogy__logo"
          src="/antlogy_logo.png"
          height="36px"
          alt=""
          style={{ objectFit: "cover" }}
        />
        {/* <h4 className="homeIcon">Everything</h4> */}
      </Link>
      {currentUserOrg === "Antlogy" && (
        <Link to="/webadmin">
          <button className="uniquelinkhome" />
        </Link>
      )}
      <div className="welcomeHome">
        <div className="wH__upperRow">
          <div className="wH__everything">
            <GoBackButton />
            <h2>Personal Space</h2>
            <BsChevronRight
              size="1.2em"
              strokeWidth="0.5"
              className="si__breadcrumb_arrowright"
            />
          </div>
        </div>
        {numOfPrTasks > 0 && (
          <div className="wH__bottomRow">
            <h4>
              {timeOfDay}, {name}!
            </h4>
            <p>
              You have <strong>{numOfPrTasks} undone{" "}
              {numOfPrTasks > 1 ? "tasks" : "task"}</strong> to work on.
            </p>
            <p className="wH__bottomRow-dragtip">
              To schedule, you may drag{" "}
              {numOfPrTasks > 1 ? "them" : "it"} onto the calendar.
            </p>
          </div>
        )}
        {numOfPrTasks === 0 && (
          <div className="wH__bottomRow">
            <h4>
              {timeOfDay}, {name}!
            </h4>
            <p>You don't have anything planned.</p>
            {/* <p>
            If you want to plan now, go inside
              a Workstation / Project to create tasks...
            </p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeHome;
