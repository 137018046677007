import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { auth, db, registerUserFb } from "./firebase/firebase.utils";

import { setCurrentUser, signOut, setUsers } from "./redux/user/user.actions";
import { setLoading } from "./redux/history/history.actions";
import { logOut } from "./redux/space/space.actions";

import { AnimatePresence } from "framer-motion";

import LayoutFront from "./pages/welcome-page/frontpages/LayoutFront";
import Space from "./pages/space/space.component.class";
import Home from "./pages/home/home.component.class";
import DockStation from "./pages/dock-station/dock-station.component";
import Station from "./pages/station/station.component";
import WelcomePage from "./pages/welcome-page/welcome-page.component";
import ProtectedRoutes from "./components/protectedRoutes/protectedRoutes.component";
import EnterStation from "./pages/enter-station/enter-station.component";
import Chat from "./pages/chat/chat.component";
import LoadingPage from "./components/retro/loading-page/loading-page.component";
import MobileMenu from "./components/mobile-menu/mobile-menu.component";
import PersonNotifications from "./components/notifications/person-notifications.component";
import TaskAdd from "./components/task-detail/task-add.component";
import TaskDetail from "./components/task-detail/task-detail.component";

import Navbar from "./pages/welcome-page/Nav/Navbar";
import Footer from "./pages/welcome-page/Nav/Footer";
import BlogArchive from "./pages/welcome-page/frontpages/blog.component";
import BlogPostSingle from "./pages/welcome-page/frontpages/blogPost.single";
import SignIn from "./pages/sign-in/sign-in.component.class";

import PrivacyPolicy from "./pages/welcome-page/Sections/legal/privacypolicy";
import TermsConditions from "./pages/welcome-page/Sections/legal/termsandconditions";
import Cookies from "./pages/welcome-page/Sections/legal/cookies";

import WebAdmin from "./pages/webadmin/webadmin.page";

import NotFoundPage from "./pages/404/notFound.component";

// import ScrollToHashElement from "./utils/scrollToHash";

import "./app.styles.scss";

function App() {
  const user = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.history.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { hash } = useLocation();
  
  
  const signInUrl = location.pathname.split("/")[1];
  const { currentUser } = user;

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      console.log("auth change App", user);
      if (user) {
        const { uid } = user;
        const userRef = await db.collection("users").doc(uid).get();
        const userData = userRef.data();
        if (userData) {
          console.log("got user in db, only dispatch");
          dispatch(setCurrentUser(userData));
          // navigate("/app");
          if (location.pathname === "/") {
            navigate("/app");
          }
          if (signInUrl === "signin") {
            navigate("/app");
          }
        }
        if (!userData) {
          console.log("no user in db");
          if (user.displayName === null) {
            return;
          } else {
            console.log("no user, creating");
            // nema usera u db. vjerovatno login putem googla
            console.log(user);
            await registerUserFb(user, user.displayName);
            const newUserRef = await db.collection("users").doc(uid).get();
            const newUserData = newUserRef.data();
            console.log(userData);
            dispatch(setCurrentUser(newUserData));
            if (signInUrl === "signin") {
              navigate("/app");
            }
          }
        }
      } else {
        console.log("no user");
        // navigate("/signin");
        dispatch(signOut());
        dispatch(logOut());
        dispatch(setLoading(true));
      }
      // dispatch(setLoading(false));
    });
  }, []);

  useEffect(() => {
    db.collection("users").onSnapshot((usersData) => {
      console.log("db users changed, dispatching setUsers");
      let users = [];
      usersData.forEach((userData) => {
        users.push(userData.data());
      });
      dispatch(setUsers(users));
    });
  }, [dispatch]);

  //To scroll to top when the page changes
  useEffect(() => {
    if (!hash.length) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const selectedTheme = localStorage.getItem("selectedTheme");

  if (selectedTheme === "silver-theme") {
    document.body.setAttribute("data-theme", "silver-theme");
  }
  if (selectedTheme === "craft-theme") {
    document.body.setAttribute("data-theme", "craft-theme");
  }
  if (selectedTheme === "paper-theme") {
    document.body.setAttribute("data-theme", "paper-theme");
  }
  if (selectedTheme === "dark-theme") {
    document.body.setAttribute("data-theme", "dark-theme");
  }


  return (
    <div className="app">
      {/* <ScrollToHashElement /> */}
      <Routes>
        <Route path="/" element={<LayoutFront />}>
          <Route index element={<WelcomePage />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="termsandconditions" element={<TermsConditions />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="blog" element={<BlogArchive />} />
          <Route path="blog/:slug" element={<BlogPostSingle />} />
          <Route path="blog/topic/:topicId" element={<BlogArchive />} />
          <Route path="blog/tag/:tagId" element={<BlogArchive />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route element={<ProtectedRoutes isAuth={currentUser} isLoading={isLoading} />}>
          <Route
            path={"/app"}
            element={<>{isLoading &&  <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Home /><Chat /></>}
            isAuth={currentUser}
            key="home"
          />
          <Route
            path={"/app/addtask"}
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Home /><TaskAdd mode="add" /></>}
            isAuth={currentUser}
            key="home"
          />
          <Route
            path={"/app/task/:taskId"}
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Home /><TaskDetail mode="edit" /></>}
            isAuth={currentUser}
          />
          <Route
            path="/s/:wspaceid"
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Station /><DockStation /><Chat /></>}
            isAuth={currentUser}
          />
          <Route
            path="/s/:wspaceid/addtask"
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Station /><DockStation /><TaskAdd mode="add" /><Chat /></>}
            isAuth={currentUser}
          />
          <Route
            path="/s/:wspaceid/e/:projectid/*"
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Station /><EnterStation isAuth={currentUser} /><Chat /></>}
          />
          <Route
            path="/s/sharedwithme/e/:projectid/*"
            element={<>{isLoading && <LoadingPage />}<Space /><MobileMenu /><PersonNotifications /><Station /><EnterStation isAuth={currentUser} /><Chat /></>}
          />
          <Route
            path="/webadmin"
            element={<><Space /><MobileMenu /><PersonNotifications /><WebAdmin isAuth={currentUser} /><Chat /></>}
          />
          <Route
            path="/webadmin/addtask"
            element={<><Space /><MobileMenu /><PersonNotifications /><WebAdmin isAuth={currentUser} /><TaskAdd mode="add" /></>}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
