import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./pricing.scss";

import SignUp from "../../sign-up/sign-up.component.class";
import RetroButton from "../../../components/retro/button/retro-button.component";
import BoxLayer from "../../../components/retro/box-layer/box-layer.component";

import LoadingPage from "../../../components/retro/loading-page/loading-page.component";

import { createCheckoutSession } from "../../../stripe/createCheckoutSession";
import usePremiumStatus from "../../../stripe/usePremiumStatus";

import { TbCheck, TbX } from "react-icons/tb";

export default function SectionPricing() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showRegister, setShowRegister] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const currentUserUid = currentUser?.uid;

  const premiumPrice = "price_1NTIpaAZXjizkbt76oJB431I";
  const businessPrice = "price_1NTlP1AZXjizkbt7NhQXVIYT";

  const handleCheckoutSessionStart = (price) => {
    setLoading(true);
    // console.log("USERID IN COMPONENT,", currentUserUid);
    createCheckoutSession(currentUserUid, price);
  };

  return (
    <section id="pricing" className="sec__pricing">
      {loading && <LoadingPage />}
      <div className="sec__pricing-heading">
        <h2>Pricing</h2>
        <h3>Upgrade, downgrade, or cancel at anytime.</h3>
      </div>
      <div className="sec_priceoptions">
        <div className="sec_priceoption">
          <h4>Free</h4>
          <h6>Free for lifetime</h6>
          <p>For newcomers who need access to only basic features</p>
          <ul>
            {" "}
            <TbCheck /> <p>Create 1 Workspace</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Create up to 4 Projects</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Board & List Views</p>
          </ul>
          <ul>
            {" "}
            <TbX /> <p>Create & organize Folders</p>
          </ul>
          <ul>
            {" "}
            <TbX /> <p>Gantt View & Milestones</p>
          </ul>
          <ul>
            {" "}
            <TbX /> <p>Task Dependencies</p>
          </ul>
          <ul>
            {" "}
            <TbX /> <p>Set duration estimates</p>
          </ul>
          <ul>
            {" "}
            <TbX /> <p>Smart time-blocking based on duration estimates</p>
          </ul>
          <ul>
            <TbX /> <p>Play chess with colleagues</p>
          </ul>
          <RetroButton
            // style={{ background: "#3b3b3b", color: "white" }}
            onClick={() => setShowRegister(!showRegister)}
          >
            GET STARTED
          </RetroButton>
        </div>
        <div className="sec_priceoption">
          <h4>Premium</h4>
          <h6>
            <sup>US </sup>
            <b>$7</b> per month
          </h6>
          <p>Gain access to Antlogy Webapp Premium Features</p>
          <ul>
            {" "}
            <TbCheck /> <p>Create up to 4 Workspaces</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Create up to 24 Projects</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Board & List & Calendar Views</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Create & organize Folders</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Gantt View & Milestones</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Task Dependencies</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Set duration estimates</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Smart time-blocking based on duration estimates</p>
          </ul>
          <ul>
            <TbCheck /> <p>Play chess with colleagues</p>
          </ul>
          <RetroButton
            // value={premiumPrice}
            onClick={() => handleCheckoutSessionStart(premiumPrice)}
          >
            UPGRADE (Soon...)
          </RetroButton>
        </div>
        <div className="sec_priceoption">
          <h4>Business</h4>
          <h6>
            <sup>US </sup>
            <b>$12</b> per user per month
          </h6>
          <p>Gain access to Antlogy Webapp Business Features</p>

          <ul>
            {" "}
            <TbCheck /> <p>Create up to 10 Workspaces</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Create Unlimited Projects</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Board & List & Calendar Views</p>
          </ul>
          <ul>
            <TbCheck /> <p>Create & organize Folders</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Gantt View & Milestones</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Task Dependencies</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Set duration estimates</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Smart time-blocking based on duration estimates</p>
          </ul>
          <ul>
            {" "}
            <TbCheck /> <p>Play chess with colleagues</p>
          </ul>
          <RetroButton
            value={businessPrice}
            onClick={() => handleCheckoutSessionStart(businessPrice)}
          >
            UPGRADE (Soon...)
          </RetroButton>
        </div>
      </div>
      {showRegister && (
        <BoxLayer setLayer={setShowRegister}>
          <SignUp />
        </BoxLayer>
      )}
    </section>
  );
}
