import { db, firebase } from "../firebase/firebase.utils";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(currentUserUid, selectedPrice) {

    // Create a new checkout session in the subcollection unside this users document
    const checkoutSessionRef = await db.collection("users").doc(currentUserUid).collection("checkout_sessions").add({
        price: selectedPrice,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
    });

    // Wait for the CheckoutSession to get attached by the extension
    checkoutSessionRef.onSnapshot(async (snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
            //We have a session, let's redirect to Checkout
            //Init Stripe
            const stripe = await getStripe();
            stripe.redirectToCheckout({ sessionId });
        }
    });
}