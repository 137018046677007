import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  db,
  fieldValue,
  removeProjectMember,
} from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";

import useCurrentMembers from "../../hooks/useCurrentMembers.hook";

import RetroAvatar from "../retro/avatar/avatar.component";
import RetroButton from "../retro/button/retro-button.component";
import RetroInput from "../retro/input/input.component";
import Member from "./member/member.component";
import PrMember from "./member/prmember.component";

import BoxLayer from "../retro/box-layer/box-layer.component";

import "./members.styles.scss";
import { AnimatePresence } from "framer-motion";

import { FaTrashAlt } from "react-icons/fa";

const PrMembers = ({ station, setShowProjectMembers }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeSpaceId = useSelector((state) => state.history.spaceId);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [email, setEmail] = useState("");
  const [invite, setInvite] = useState(false);
  const [prmemberData, setPrMemberData] = useState([]);
  const inputRef = useRef();

  const [deleteStatus, setDeleteStatus] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  useEffect(() => {
    const getProjectMembers = async () => {
      let listDocs = [];
      const ref = db.collection("users").where("uid", "in", station?.members);
      const docs = await ref.get();
      docs?.forEach((doc) => {
        listDocs.push(doc.data());
      });
      setPrMemberData(listDocs);
    };
    // call the function
    getProjectMembers();
    //   // make sure to catch any error
    //   .catch(console.error);
  }, [activeSpaceId, invite]);

  const handlePrMembSubmit = (e) => {
    e.preventDefault();
    const sharedProjectRef = {
      stationId: station.id,
      fromSpaceId: station.fromSpaceId,
      invitedBy: currentUser.uid,
    };
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        if (doc.empty) {
          alert("No user in database. User must be registered");
        } else {
          doc.forEach((data) => {
            db.collection("space")
              .doc(activeSpaceId)
              .collection("stations")
              .doc(station.id)
              .update({
                members: fieldValue.arrayUnion(data.data().uid),
              })
              .then(() => {
                db.collection("users")
                  .doc(data.data().uid)
                  .update({
                    sharedWith: fieldValue.arrayUnion(sharedProjectRef),
                  });
              })
              .then(() => {
                alert("user added");
                inputRef.current.value = "";
                setInvite(false);
                // history.go(0);
              });
          });
        }
      });
  };

  const handleSetMembertoRemove = (member) => {
    setMemberToDelete(member);
    setDeleteStatus(true);
  };

  const handleRemoveProjectMember = () => {
    // console.log()
    const sharedProjectRef = {
      stationId: station.id,
      fromSpaceId: station.fromSpaceId,
      invitedBy: currentUser.uid,
    };
    removeProjectMember(
      station.fromSpaceId,
      station.id,
      memberToDelete.uid,
      sharedProjectRef
    );
    alert("project member removed");
    setDeleteStatus(false);
    // history.go(0);
    setShowProjectMembers(false);
  };

  return (
    <>
      <details open={prmemberData.length > 0}>
        <summary>
          <div className="EmojiIcon">{station.emojicon}</div>
          <a>{station.name}</a>
          {/* <p>{prmemberData.length}</p> */}
        </summary>
        {prmemberData?.map((member) => {
          return (
            // <PrMember
            //   key={member.uid}
            //   member={member}
            //   activeSpaceData={activeSpaceData}
            //   activeSpaceId={activeSpaceId}
            // />
            <div className="prmember" key={member.uid}>
              <div className="prmember__user">
                <div className="prmember__avatar">
                  <RetroAvatar src={member.imageUrl} />
                </div>
                <div className="member__name">
                  <p>{member.userName}</p>
                </div>
              </div>
              <div className="member__controls">
                <div
                  className="member__delete"
                  onClick={() => handleSetMembertoRemove(member)}
                >
                  <FaTrashAlt />
                </div>
              </div>
            </div>
          );
        })}
        <AnimatePresence>
          {deleteStatus && (
            <BoxLayer setLayer={setDeleteStatus}>
              <h3>
                Sure you want to remove {memberToDelete.userName} from the
                project?
              </h3>
              <div className="member__deleteBtns">
                <RetroButton onClick={() => setDeleteStatus(false)} mode="gray">
                  cancel
                </RetroButton>
                <RetroButton
                  onClick={() => handleRemoveProjectMember(memberToDelete)}
                >
                  yes
                </RetroButton>
              </div>
            </BoxLayer>
          )}
        </AnimatePresence>
        {invite && (
          <form onSubmit={handlePrMembSubmit}>
            <div
              className="members__invite"
              onChange={(e) => setEmail(e.target.value)}
            >
              <RetroInput
                ref={inputRef}
                placeholder="email"
                type="email"
                autofocus="autofocus"
              />
            </div>
          </form>
        )}
        <div className="members__button">
          {invite && (
            <>
              <RetroButton mode="gray" onClick={() => setInvite(false)}>
                cancel
              </RetroButton>
              <RetroButton color="info" onClick={(e) => handlePrMembSubmit(e)}>
                Submit
              </RetroButton>
            </>
          )}
          {!invite && (
            <RetroButton
              color="info"
              onClick={() => setInvite(!invite)}
              type="submit"
            >
              Invite to Project
            </RetroButton>
          )}
        </div>
      </details>
    </>
  );
};

export default PrMembers;
