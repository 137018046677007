import React from 'react'
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        <div>
            <h2>404</h2>
            <h3>Ooops! Nothing found here</h3>
            <Link to="/">Redirecting to the Home Page</Link>
        </div>
    )
}

export default NotFoundPage;