import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase.utils";
import { useParams, Link } from "react-router-dom";
import { AnimatePresence, motion, stagger } from "framer-motion";
import "./blog.styles.scss";
import BlogPostCard from "../components/blogPostCard";

import { BsFunnel, BsFunnelFill, BsSearch } from "react-icons/bs";

const categories = [
  "Personal Features",
  "Team Features",
  "Methodologies",
  "Software Reviews",
  "News",
  "Updates",
];
const tags = ["pomodoro", "agile", "kanban", "critical path"];

export default function BlogArchive() {
  const [topics, setTopics] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [tagFilters, setTagFilters] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const { topicId, tagId } = useParams();

  const BlogRef = db.collection("frontend").doc("blog");
  const PostsRef = db.collection("frontend").doc("blog").collection("posts");

  useEffect(() => {
    BlogRef.get().then((doc) => setTopics(doc.data().topics));
    // const categoryData = blogData.data().topics;
    // setTopics(categoryData);
  }, []);

  const allTagsRef1 = blogPosts.map((post) => post.tags);
  const allTags2 = allTagsRef1.filter((arr) => arr.length > 0);
  const allTags = allTags2.flat(1);

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  useEffect(() => {
    PostsRef.where("status", "==", "published").onSnapshot((collection) => {
      let posts = [];
      collection.forEach((doc) => {
        posts.push({ ...doc.data(), id: doc.id });
      });
      setBlogPosts(posts);
      setFilteredPosts(posts);
    });
  }, []);

  useEffect(() => {
    if (topicId) {
      const categoryResults = blogPosts.filter((post) =>
        post.categories.includes(topicId)
      );
      setFilteredPosts(categoryResults);
    }
  }, [topicId]);

  useEffect(() => {
    if (tagId) {
      const tagResults = blogPosts.filter((post) => post.tags.includes(tagId));
      setFilteredPosts(tagResults);
    }
  }, [tagId]);

  const handleSubmit = (e) => e.preventDefault();

  const handleSearchChange = (e) => {
    if (!e.target.value) return setFilteredPosts(blogPosts);
    const searchResults = blogPosts.filter(
      (post) =>
        post.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        post.content.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredPosts(searchResults);
  };

  const handleTagFilterNew = (tag) => {
    // const newArr = [];
    // setFilteredNotes(newArr);
    if (tagFilters.includes(tag)) {
      const findIndex = tagFilters?.findIndex((item) => item === tag);
      const tagFiltersRef = tagFilters;
      tagFiltersRef?.splice(findIndex, 1);
      setTagFilters(tagFiltersRef);
      console.log("FIRST IF RESULT", tagFiltersRef);
      const filteredPostsRef = Object.values(blogPosts).filter((n) =>
        n.tags.some((r) => tagFilters.includes(r))
      );
      setFilteredPosts(filteredPostsRef);
      if (tagFilters.length === 0) {
        setTagFilters([]);
      }
    } else {
      const refTagArray = tagFilters;
      refTagArray.push(tag);
      setTagFilters(refTagArray);
      console.log("SECOND IF RESULT", refTagArray);
      const filteredNotesRef = Object.values(blogPosts).filter((n) =>
        n.tags.some((r) => tagFilters.includes(r))
      );
      setFilteredPosts(filteredNotesRef);
    }
  };

  // console.log("blogposts is:", blogPosts);

  function formatLetters(string) {
    const stringwithspaces = string.replaceAll("-", " ");
    return stringwithspaces.charAt(0).toUpperCase() + stringwithspaces.slice(1);
  }

  return (
    <section id="resources" className="blogposts-archive">
      <motion.div
        initial={{ x: "-160px", opacity: 0 }}
        animate={{ x: "0px", opacity: 1 }}
        exit={{ x: "-160px", opacity: 0.8 }}
        transition={{ type: "ease-in-out", duration: 0.4, delay: 0.6 }}
        className="blogarchive-sidebar"
      >
        {/* <h2>News & Arcticles</h2> */}
        <form onSubmit={handleSubmit}>
          <BsSearch size="1.4em" />
          <input type="text" id="search" onChange={handleSearchChange} />
        </form>
        <div className="blog_sidebar-section">
          <h3>Categories</h3>
          <div className="blog_categories">
            {topics.map((topic) => (
              <Link
                to={`/blog/topic/${topic.replace(/\s+/g, "-").toLowerCase()}`}
              >
                <button
                  className={
                    topicId === topic ? "blogcategory-active" : "blogcategory"
                  }
                  key={topic}
                >
                  {formatLetters(topic)}
                </button>
              </Link>
            ))}
          </div>
        </div>
        <div className="blog_sidebar-section">
          <h3>Tags</h3>
          <div className="blog_tags">
            {/* {tags.map((tag) => (
              <Link to={`/blog/tag/${tag.replace(/\s+/g, "-").toLowerCase()}`}>
                <button key={tag}>{tag}</button>
              </Link>
            ))} */}
            {removeDuplicates(allTags).map((tag) => {
              const tagisactive = tagFilters.includes(tag);
              return (
                <button
                  className={
                    tagisactive ? "blog_tag-btn-active" : "blog_tag-btn"
                  }
                  key={tag}
                  onClick={() => handleTagFilterNew(tag)}
                >
                  {tag}
                </button>
              );
            })}
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ x: "-160px", opacity: 0 }}
        animate={{ x: "0px", opacity: 1 }}
        exit={{ x: "-160px", opacity: 0.8 }}
        stagger={(0.5, { startDelay: 0.8 })}
        transition={{ type: "ease-in-out", duration: 0.4, delay: 0.6 }}
        className="blogposts-container"
      >
        {filteredPosts.map((post) => {
          const { id, title, content, featuredImage } = post;
          return <BlogPostCard key={id} post={post} />;
        })}
      </motion.div>
    </section>
  );
}
