import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";

import "./webadmin.styles.scss";

import BlogEditor from "./components/blog-editor";
import EmailEditor from "./components/email-editor";
import NotificationEditor from "./components/notification-editor";
import UserManagement from "./components/user-management";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

// import "./notes.styles.scss";

// import Editor from "../../components/editor/editor"

export default function WebAdmin() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const users = useSelector((state) => state.user.users);
  const [allUsers, setAllUsers] = useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [paidUsers, setPaidUsers] = useState([]);
  const [activeTab, setActiveTab] = useState("BlogEditor");

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.organization !== "Antlogy") {
      navigate("/");
    }
  }, [currentUser]);

  useEffect(() => {
    const paidUsersRef = users.filter((item) => item.plan === "premium");
    const freeUsersRef = users.filter((item) => item.plan === "premium");
    setPaidUsers(paidUsersRef);
    setFreeUsers(freeUsersRef);
  }, [users]);

  return (
    <div className="adminpage">
      <div className="admin__content">
        <div className="adminpage-header">
          <Link to="/" className="admin__antlogy-logo">
            <img
              classname="antLogy__logo"
              src="/antlogy_logo.png"
              height="40px"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </Link>
          <button
            className={
              activeTab === "BlogEditor" ? "admin__tab-active" : "admin__tab"
            }
            onClick={() => setActiveTab("BlogEditor")}
          >
            Blog
          </button>
          <button
            className={
              activeTab === "UserManagement"
                ? "admin__tab-active"
                : "admin__tab"
            }
            onClick={() => setActiveTab("UserManagement")}
          >
            Users
          </button>
          <button
            className={
              activeTab === "EmailEditor" ? "admin__tab-active" : "admin__tab"
            }
            onClick={() => setActiveTab("EmailEditor")}
          >
            Email
          </button>
          <button
            className={
              activeTab === "NotificationEditor"
                ? "admin__tab-active"
                : "admin__tab"
            }
            onClick={() => setActiveTab("NotificationEditor")}
          >
            Notification
          </button>
        </div>
        <div className="admin__tab-content">
          {activeTab === "UserManagement" && <UserManagement />}
          {activeTab === "EmailEditor" && <EmailEditor />}
          {activeTab === "NotificationEditor" && <NotificationEditor />}
          {activeTab === "BlogEditor" && <BlogEditor />}
        </div>
        <Outlet />
        <TaskAddButton />
      </div>
    </div>
  );
}
