import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import "./anteditor.scss";

import { createPortal } from "react-dom";

import { createNewInboxTask } from "../../../firebase/firebase.utils";

import AntListens from "./plugins/antListens";

import ColorPalette from "../../colors/color-palette";

import isValidHttpUrl from "./plugins/autoLinkPlugin";

// import { Picker } from "emoji-mart";
import Picker from "@emoji-mart/react";

// import SpeechMic from "./plugins/SpeechMic";

import {
  BsQuote,
  BsFileBreak,
  BsTable,
  BsCardImage,
  BsPlayBtn,
  BsTextParagraph,
  BsParagraph,
  BsEmojiSmile,
  BsPaintBucket,
} from "react-icons/bs";
import { AiOutlineHighlight } from "react-icons/ai";
import {
  TbBold,
  TbItalic,
  TbUnderline,
  TbStrikethrough,
  TbAlignLeft,
  TbAlignRight,
  TbAlignCenter,
  TbAlignJustified,
  TbIndentIncrease,
  TbIndentDecrease,
  TbList,
  TbListNumbers,
  TbListCheck,
  TbSquareToggleHorizontal,
  TbChevronDown,
  TbPlus,
} from "react-icons/tb";
import { TfiLink, TfiLayoutMediaRightAlt, TfiParagraph } from "react-icons/tfi";
import {
  HiOutlineArrowUturnLeft,
  HiOutlineArrowUturnRight,
  HiOutlineCodeBracket,
  HiPaintBrush,
} from "react-icons/hi2";
import EmojiPicker from "../../emoji/emojiPicker";

export default function AntEditor({
  mainToolbarOn,
  floatingToolbarOn,
  placeholder,
  initialValue,
  setDocContent,
  setDocImgFile,
  setDocImgFileAltText,
  docImgFileAltText,
  handleUploadDocImage,
}) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [html, setHTML] = useState("");
  const [htmll, setHTMLL] = useState("");
  const [preview, setPreview] = useState("");
  const editor = useRef(null);
  const editorr = useRef(null);
  const testRef = useRef(null);
  const antToolbarRef = useRef(null);
  // const alignDropdownRef = useRef(null);

  const selectionLocation = useRef(null);

  const [showBlockOptions, setShowBlockOptions] = useState(false);
  const [blockType, setBlockType] = useState("Normal");

  const [showAlignOptions, setShowAlignOptions] = useState(false);
  const [alignType, setAlignType] = useState("Left");

  const [showInsertOptions, setShowInsertOptions] = useState(false);

  const [showTextColors, setShowTextColors] = useState(false);
  const [showHighlightColors, setShowHighlightColors] = useState(false);

  const [floatingToolbarWanted, setFloatingToolbarWanted] = useState(true);
  const [showFloatingToolbar, setShowFloatingToolbar] = useState(false);

  const [emisOpen, setEmIsOpen] = useState(false);

  const [spokenText, setSpokenText] = useState(false);

  const [showInsertDropDown, setShowInsertDropDown] = useState(false);
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [showFileImgModal, setShowFileImgModal] = useState(false);
  const [showLinkImgModal, setShowLinkImgModal] = useState(false);

  const [showLinkVidModal, setShowLinkVidModal] = useState(false);
  const [vidFromWebLink, setVidFromWebLink] = useState(null);

  const [imgFromWebLink, setImgFromWebLink] = useState(null);

  const [enterPressedOnce, setEnterPressedOnce] = useState(false);

  const targetNodeRef = useRef(null);
  const savedSelectionRef = useRef(null);

  //   function utilizeSanitize(htmlString) {
  //     const sanitizedHtml = sanitizeHtml(htmlString, {
  //       allowedTags: [
  //         "h2",
  //         "h3",
  //         "h4",
  //         "h5",
  //         "p",
  //         "b",
  //         "i",
  //         "em",
  //         "strong",
  //         "a",
  //         "img",
  //         "details",
  //         "summary",
  //         "table",
  //         "tr",
  //         "td",
  //         "tbody",
  //         "thead",
  //         "iframe",
  //         "br",
  //       ],
  //       allowedAttributes: {
  //         a: ["href", "name", "target"],
  //         p: ["style"],
  //         // We don't currently allow img itself by default, but
  //         // these attributes would make sense if we did.
  //         img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
  //         iframe: ["src", "width", "height", "frameborder", "allowfullscreen"],
  //         table: ["border", "width"],
  //       },
  //       allowedStyles: {
  //         '*': {
  //           // Match HEX and RGB
  //           'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
  //           'text-align': [/^left$/, /^right$/, /^center$/],
  //           // Match any number with px, em, or %
  //           'font-size': [/^\d+(?:px|em|%)$/]
  //         },
  //         'p': {
  //           'font-size': [/^\d+rem$/]
  //         }
  //       }
  //       // allowedIframeHostnames: ["www.youtube.com"],
  //     });
  //     return sanitizedHtml;
  //   }

  function utilizeSanitize(htmlString) {
    const sanitizedHtml = sanitizeHtml(htmlString);
    return htmlString;
  }

  //   function utilizeSanitize(htmlString) {
  //     const sanitizedHtml = sanitizeHtml(htmlString);
  //     return sanitizedHtml;
  //   }

  useEffect(() => {
    const targetNode = targetNodeRef.current;

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        console.log(mutation.target.innerHTML);
      });
    });

    const config = {
      attributes: true,
      childList: true,
      subtree: true,
      CharacterData: true,
    };

    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, [targetNodeRef.current]);

  // const cleanNoteText = sanitizeHtml(note.text, {
  //     allowedTags: ["b", "i", "em", "strong", "a", "img"],
  //     allowedAttributes: {
  //       a: ["href", "name", "target"],
  //       // We don't currently allow img itself by default, but
  //       // these attributes would make sense if we did.
  //       img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
  //     },
  //     allowedIframeHostnames: ["www.youtube.com"],
  //   });

  useEffect(() => {
    const editor = document.getElementById("editorr");
    // const paragraph = document.createElement('p');
    // editor.append(paragraph)
    document.execCommand("formatBlock", false, "p");
    const listener = () => {
      const cleanContentText = utilizeSanitize(editor.innerHTML);
      // const cleanContentText = editor.innerHTML;
      setHTMLL(cleanContentText);
      setDocContent(cleanContentText);
    };
    editor.addEventListener("input", listener);
    editor.addEventListener("click", listener);
    editor.addEventListener("mouseup", listener);
    return () => {
      editor.removeEventListener("input", listener);
      editor.removeEventListener("click", listener);
      editor.removeEventListener("mouseup", listener);
    };
  }, []);

  useEffect(() => {
    const editorelement = document.getElementById("editorr");
    editorelement.innerHTML = utilizeSanitize(initialValue);
  }, [initialValue]);

  //AUTOLINK for pasted text
  useEffect(() => {
    const editor = document.getElementById("editorr");

    editor.addEventListener("paste", (event) => {
      // event.preventDefault();

      let paste = (event.clipboardData || window.clipboardData).getData("text");
      //   paste = paste.toUpperCase();
      const selection = window.getSelection();

      const isUrl = isValidHttpUrl(paste);

      if (isUrl) {
        const a = document.createElement("a");
        a.setAttribute("href", paste);
        a.setAttribute("title", paste);
        a.setAttribute("target", "_blank");
        a.setAttribute("rel", "noopener noreferrer");
        a.appendChild(document.createTextNode(` ${paste} `));
        selection.getRangeAt(0).insertNode(a);
        selection.collapseToEnd();
      } else {
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
      }
    });
  }, []);

  function createImageFromURL({ src, altText }) {
    const editorelement = document.getElementById("editorr");
    const img = document.createElement("img");
    img.setAttribute("src", src);
    img.setAttribute("alt", altText);
    editorelement.append(img);
    setShowLinkImgModal(false);
  }

  const onImageConfirmClick = async () => {
    const url = await handleUploadDocImage();
    createImageFromURL({
      src: url,
      altText: docImgFileAltText,
    });
    setShowFileImgModal(false);
  };

  function createCollapsible() {
    const editorelement = document.getElementById("editorr");

    const collapsibleContainer = document.createElement("details");
    collapsibleContainer.textContent = "Details part";
    const collapsibleTitle = document.createElement("summary");
    collapsibleTitle.textContent = "Summary part";
    collapsibleContainer.insertAdjacentElement("afterbegin", collapsibleTitle);
    collapsibleContainer.setAttribute("open", true);

    editorelement.append(collapsibleContainer);
  }

  // function selection() {
  //     if (window.getSelection)
  //         return window.getSelection();
  // }

  function makeBold() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const bold = document.createElement("b");
    bold.appendChild(range.extractContents());
    range.insertNode(bold);
  }

  function toggleBold() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const bold = document.createElement("b");
    const isBold =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "b";
    if (isBold) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.remove();
      range.insertNode(text);
    } else {
      bold.appendChild(range.extractContents());
      range.insertNode(bold);
    }
  }

  function toggleItalic() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const italic = document.createElement("i");
    const isItalic =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "i";
    if (isItalic) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.remove();
      range.insertNode(text);
    } else {
      italic.appendChild(range.extractContents());
      range.insertNode(italic);
    }
  }

  function toggleUnderline() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const underline = document.createElement("u");
    const isUnderline =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "u";
    if (isUnderline) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.remove();
      range.insertNode(text);
    } else {
      underline.appendChild(range.extractContents());
      range.insertNode(underline);
    }
  }

  function toggleStrike() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const strikethrough = document.createElement("u");
    const isStrikeThrough =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() ===
      "strikethrough";
    if (isStrikeThrough) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.remove();
      range.insertNode(text);
    } else {
      strikethrough.appendChild(range.extractContents());
      range.insertNode(strikethrough);
    }
  }

  function handleUndo() {
    document.execCommand("undo", false, null);
  }

  function handleRedo() {
    document.execCommand("undo", false, null);
  }

  function handleAlignCommands(alignValue) {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const parentEl = range.commonAncestorContainer.parentElement;
    parentEl.style.textAlign = alignValue;
  }

  function handleChangeType(type) {
    // const selection = window.getSelection();
    // const range = selection.getRangeAt(0);
    // const elementtype = document.createElement(type);
    // const isSameSelected = range.commonAncestorContainer.parentNode.tagName.toLowerCase() === type;
    // if (!isSameSelected) {
    //     const text = range.extractContents();
    //     range.commonAncestorContainer.parentNode.remove()
    //     range.insertNode(text);
    // } return
    document.execCommand("formatBlock", false, type);
  }

  function handleIndentation(type) {
    document.execCommand(type, false, null);
  }

  function handleInsertList(type) {
    document.execCommand(type, false, null);
  }


  const handleInsertChecklist = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.checked = true; // Set the default value to checked or unchecked based on your requirement
    checkbox.setAttribute('contenteditable', 'false'); // Make the checkbox not editable

    const listItem = document.createElement('div');
    listItem.appendChild(checkbox);

    range.deleteContents();
    range.insertNode(listItem);

    // Move the cursor to the end of the new list item
    const newRange = document.createRange();
    newRange.setStartAfter(checkbox);
    newRange.setEndAfter(checkbox);

    selection.removeAllRanges();
    selection.addRange(newRange);

    // Handle Enter key
    const editor = targetNodeRef.current;
    editor.addEventListener('keydown', handleEnterKey);

    function handleEnterKey(event) {
      if (event.key === 'Enter') {
        event.preventDefault();

        const newCheckbox = document.createElement('input');
        newCheckbox.type = 'checkbox';
        newCheckbox.checked = true; // Set the default value to checked or unchecked based on your requirement
        newCheckbox.setAttribute('contenteditable', 'false'); // Make the checkbox not editable

        const newListItem = document.createElement('div');
        newListItem.appendChild(newCheckbox);

        range.deleteContents();
        range.insertNode(newListItem);

        // Move the cursor to the end of the new list item
        const newRange = document.createRange();
        newRange.setStartAfter(newCheckbox);
        newRange.setEndAfter(newCheckbox);

        selection.removeAllRanges();
        selection.addRange(newRange);

        editor.removeEventListener('keydown', handleEnterKey);
      }
    }

    // Add event listener to update HTML when checkbox is clicked
    checkbox.addEventListener('change', handleCheckboxChange);
  };

  // Update HTML content when checkbox is clicked
  const handleCheckboxChange = () => {
    const editor = targetNodeRef.current;
    const checkboxes = editor.querySelectorAll('input[type="checkbox"]');
    const updatedHTML = Array.from(checkboxes)
      .map((checkbox) => `<div contenteditable="false"><input type="checkbox" ${checkbox.checked ? 'checked' : ''} /></div>`)
      .join('');

    editor.innerHTML = updatedHTML;
  };


  function createLink() {
    var url = prompt("Enter the URL:");
    document.execCommand("createLink", false, url);
  }

  function handleUnlink() {
    document.execCommand("unlink", false, null);
  }

  function FillURLYoutube(url) {
    // const url = prompt("Enter the URL of the YouTube video:", "");

    const match =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

    if (id != null) {
      return id;
    }

    return null;
  }
  function FillURLVimeo(url) {
    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.
    var match = /vimeo.*\/(\d+)/i.exec(url);

    // If the match isn't null (i.e. it matched)
    if (match) {
      // The grouped/matched digits from the regex
      return match[1];
    }
  }

  // YOUTUBE EMBED SAMPLE = <iframe width="889" height="500" src="https://www.youtube.com/embed/3oDNqHZ7UKY"
  // title="How To Make Text To Voice Converter Using JavaScript | Text To Speech Generator"
  // frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  // allowfullscreen></iframe>

  function insertYoutube(url) {
    const editorelement = document.getElementById("editorr");
    // var url = prompt("Enter the URL of the YouTube video:");
    // var id = url.split("=")[1];
    // var id = url.includes("vimeo") ? FillURLVimeo(url) : FillURLYoutube(url)
    const iframe = document.createElement("iframe");
    if (url.includes("vimeo")) {
      var id = FillURLVimeo(url);
      iframe.setAttribute("src", `https://player.vimeo.com/video/${id}`);
    } else {
      var id = FillURLYoutube(url);
      iframe.setAttribute("src", `https://www.youtube.com/embed/${id}`);
    }
    iframe.setAttribute("width", "560");
    iframe.setAttribute("height", "315");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", true);
    // var embedCode = "<iframe width='560' height='315' src='https://www.youtube.com/embed/" + id + "' frameborder='0' allowfullscreen></iframe>";
    // document.execCommand("insertHTML", false, embedCode);
    editorelement.append(iframe);
  }

  const [textColor, setTextColor] = useState(null);
  const [highlightColor, setHighlightColor] = useState(null);

  // function changeTextColor(color) {
  //     document.execCommand("foreColor", false, color);
  //     setShowTextColors(false)
  // }

  // function changeHighlightColor(color) {
  //     document.execCommand("hiliteColor", false, color);
  //     setShowHighlightColors(false)
  // }

  function changeTextColor(color) {
    // const editorelement = document.getElementById("editorr");
    // var selection = editorelement.getSelection();

    //OPTION POTENTIALLY WORKS
    // const selection = window.getSelection();
    // const range = selection.getRangeAt(0);
    // const span = document.createElement('span');
    // const isSpan = range.commonAncestorContainer.parentNode.tagName.toLowerCase() === 'span';
    // if (isSpan) {
    //     const text = range.extractContents();
    //     range.commonAncestorContainer.parentNode.remove()
    //     range.insertNode(text);
    // } else {
    //     span.appendChild(range.extractContents());
    //     span.style.color = color;
    //     range.insertNode(span);
    // }

    //OPTION SUGGESTED
    var selection = window.getSelection();
    if (selection.rangeCount) {
      var range = selection.getRangeAt(0);
      var newNode = document.createElement("span");
      newNode.style.color = color;
      newNode.appendChild(range.extractContents());
      range.insertNode(newNode);
    }
    setShowTextColors(false);
  }

  function changeHighlightColor(color) {
    var selection = window.getSelection();
    if (selection.rangeCount) {
      var range = selection.getRangeAt(0);
      var newNode = document.createElement("span");
      newNode.style.backgroundColor = color;
      // newNode.style.backgroundImage = `linear-gradient(to right,transparent50%, ${color} 100%)`;
      newNode.appendChild(range.extractContents());
      range.insertNode(newNode);
    }
    setShowHighlightColors(false);
  }

  function removeHighlightColor() {
    var selection = window.getSelection();
    if (selection.rangeCount) {
      var range = selection.getRangeAt(0);
      var parentElement = range.commonAncestorContainer.parentElement;
      if (parentElement.style.backgroundColor) {
        var textNode = document.createTextNode(parentElement.textContent);
        parentElement.replaceWith(textNode);
      }
    }
  }

  function removeTextColor() {
    var selection = window.getSelection();
    if (selection.rangeCount) {
      var range = selection.getRangeAt(0);
      var parentElement = range.commonAncestorContainer.parentElement;
      if (parentElement.style.backgroundColor) {
        var textNode = document.createTextNode(parentElement.textContent);
        parentElement.replaceWith(textNode);
      }
    }
  }

  function onSelectEmoji(emoji) {
    const editorelement = document.getElementById("editorr");
    // var emojiNode = document.createTextNode(emoji.native);
    // const selection = window.getSelection();
    if (savedSelectionRef.current) {
      savedSelectionRef.current.insertNode(
        document.createTextNode(emoji.native)
      );
      savedSelectionRef.current = null;
    } else {
      var emojiNode = document.createTextNode(emoji.native);
      editorelement.appendChild(emojiNode);
    }
    // selection.collapseToEnd();
    setEmIsOpen(!emisOpen);
  }

  function createTable() {
    var rows = prompt("Enter the number of rows:");
    var cols = prompt("Enter the number of columns:");
    var table = "<table border='1' width='100%'>";
    for (var i = 0; i < rows; i++) {
      table += "<tr>";
      for (var j = 0; j < cols; j++) {
        table += "<td> </td>";
      }
      table += "</tr>";
    }
    table += "</table>";
    document.execCommand("insertHTML", false, table);
  }

  // useEffect(() => {
  //     document.execCommand("foreColor", false, textColor);
  //     // changeTextColor(textColor)
  //     setShowTextColors(false)
  // }, [textColor])

  // useEffect(() => {
  //     document.execCommand("hiliteColor", false, highlightColor);
  //     // changeHighlightColor(highlightColor)
  //     setShowHighlightColors(false)
  // }, [highlightColor])

  // function updateEditor() {
  //     const editorInnerHtml = document.getElementById("editorr").textContent;
  //     setHTMLL(editorInnerHtml)
  //     console.log(editorInnerHtml)
  // }

  // THIS CAN BE USEFUL FOR ONCHANGE
  // useEffect(() => {
  //     const editorelement = document.getElementById("editorr");
  //     // editorelement.addEventListener('onselect', setShowFloatingToolbar(false));

  //     editorelement.addEventListener('selectionchange', (e) => {
  //         console.log("Archor node - ", window.getSelection().anchorNode);
  //         if (window.getSelection().anchorNode.parentElement === editorelement) {
  //             if (window.getSelection().anchorNode.textContent.length > 0) {
  //                 setShowFloatingToolbar(true)
  //             } else {
  //                 setShowFloatingToolbar(false)
  //             }
  //         } else { setShowFloatingToolbar(false) }
  //     });

  //     return () => {
  //         editorelement.removeEventListener('selectionchange', setShowFloatingToolbar(!showFloatingToolbar));
  //     };

  // }, [])

  // useEffect(() => {
  //     const handleSelectionChange = () => {
  //         const selection = window.getSelection();
  //         const selectionStr = selection.toString();
  //         selectionLocation.current = selection.getRangeAt(0).getBoundingClientRect()
  //         if (selectionStr.length > 0) {
  //             setShowFloatingToolbar(true);
  //         } else {
  //             setShowFloatingToolbar(false)
  //         }
  //         console.log(selectionStr);
  //     };
  //     targetNodeRef.current.addEventListener('mouseup', handleSelectionChange);
  //     return () => {
  //         targetNodeRef.current.removeEventListener('mouseup', handleSelectionChange);
  //     };
  // }, []);

  useEffect(() => {
    const editorelement = document.getElementById("editorr");
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      const selectionStr = selection.toString();
      if (selectionStr.length > 0) {
      selectionLocation.current = selection
        .getRangeAt(0)
        .getBoundingClientRect();
      if (selectionStr.length > 0) {
        setShowFloatingToolbar(true);
      }
    }
    else {
      setShowFloatingToolbar(false);
    }
      // console.log(selectionStr);
    };
    editorelement.addEventListener("mouseup", handleSelectionChange);
    return () => {
      editorelement.removeEventListener("mouseup", handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior

        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        let currentNode = range.commonAncestorContainer;

        // If currentNode is a Text node, use its parentNode for the closest check
        if (currentNode.nodeType === Node.TEXT_NODE) {
          currentNode = currentNode.parentNode;
        }

        // Check if the current selection is within a list
        const listItem = currentNode.closest('li');
        if (listItem) {
          // We are inside a list item
          if (enterPressedOnce) {
            // If Enter has been pressed once already, create a new paragraph
            const newParagraph = document.createElement('p');
            newParagraph.innerHTML = '<br>'; // Add a line break to the new paragraph

            // Insert the new paragraph after the current list item
            listItem.parentNode.insertBefore(newParagraph, listItem.nextSibling);

            // Set the focus to the new paragraph
            const newRange = document.createRange();
            newRange.setStart(newParagraph,  0);
            newRange.setEnd(newParagraph,  0);
            selection.removeAllRanges();
            selection.addRange(newRange);

            // Reset the state
            setEnterPressedOnce(false);
          } else {
            // If Enter has not been pressed once already, create a new list item
            const newListItem = document.createElement('li');
            newListItem.innerHTML = '<br>'; // Add a line break to the new list item

            // Insert the new list item after the current list item
            listItem.parentNode.insertBefore(newListItem, listItem.nextSibling);

            // Set the focus to the new list item
            const newRange = document.createRange();
            newRange.setStart(newListItem,  0);
            newRange.setEnd(newListItem,  0);
            selection.removeAllRanges();
            selection.addRange(newRange);

            // Set the state to indicate that Enter has been pressed once
            setEnterPressedOnce(true);
          }
        } else {
          // We are not inside a list item, create a new paragraph element
          const newParagraph = document.createElement('p');
          newParagraph.innerHTML = '<br>'; // Add a line break to the new paragraph

          // Insert the new paragraph after the current selection
          range.insertNode(newParagraph);

          // Set the focus to the new paragraph
          const newRange = document.createRange();
          newRange.setStart(newParagraph,  0);
          newRange.setEnd(newParagraph,  0);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    };

    const editor = targetNodeRef.current;
    editor.addEventListener("keydown", handleKeyDown);

    return () => {
      editor.removeEventListener("keydown", handleKeyDown);
    };
  }, [enterPressedOnce]);

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }


  useEffect(() => {
    if (spokenText.length > 0) {
      const editor = document.getElementById("editorr");
      const p = document.createElement("p");
      p.textContent = spokenText;
      editor.appendChild(p);
      if (spokenText.toLowerCase().includes("in my tasks")) {
        let taskTitle = spokenText.trim().substring(4, spokenText.length - 11);
        const capitalizedTask = capitalize(taskTitle);
        createNewInboxTask(currentUser.uid, capitalizedTask, null);
        let utterance = new SpeechSynthesisUtterance(
          `Sure, I added ${taskTitle} to your tasks.`
        );
        speechSynthesis.speak(utterance);
      }
    }
  }, [spokenText]);

  // var savedSel = null;
  // if (window.getSelection) {
  //     savedSel = window.getSelection().getRangeAt(0);
  // }

  // const addToTasks = (e) => {
  //     e.preventDefault();
  //     // const selection = $getSelection();
  //     var text = "";
  //     if (window.getSelection) {
  //         text = window.getSelection().toString();
  //     } else if (document.selection && document.selection.type != "Control") {
  //         text = document.selection.createRange().text;
  //     }
  //     createNewInboxTask(currentUser.uid, text);
  // };

  // const onImageConfirmDispatch = (docImgFileAltText, url) => {
  //     // editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  //     createImageFromURL(docImgFileAltText, url)
  // };

  return (
    <div className="anteditor">
      {mainToolbarOn && (
        <div
          style={{ display: "flex" }}
          className="anteditor_toolbar"
          ref={antToolbarRef}
        >
          <button onClick={handleUndo}>
            <HiOutlineArrowUturnLeft />
          </button>
          <button onClick={handleRedo}>
            <HiOutlineArrowUturnRight />
          </button>
          <button
            onClick={() => setShowBlockOptions(!showBlockOptions)}
            id="blocktypeDropdown"
            className="drop-item"
          >
            <TfiParagraph />
            <TbChevronDown />
          </button>
          {showBlockOptions &&
            createPortal(
              <div
                className="dropdown"
                style={{
                  top: `${
                    document
                      .getElementById("blocktypeDropdown")
                      .getBoundingClientRect().top + 30
                  }px`,
                  left: `${
                    document
                      .getElementById("blocktypeDropdown")
                      .getBoundingClientRect().left - 62
                  }px`,
                }}
              >
                <button className="item" onClick={() => handleChangeType("p")}>
                  <TfiParagraph />
                  Paragraph
                </button>
                <div class="divider"></div>
                <button className="item" onClick={() => handleChangeType("h2")}>
                  <span className="icon heading-2" /> Heading 2
                </button>
                <button className="item" onClick={() => handleChangeType("h3")}>
                  <span className="icon heading-3" /> Heading 3
                </button>
                <button className="item" onClick={() => handleChangeType("h4")}>
                  <span className="icon heading-4" /> Heading 4
                </button>
                <button className="item" onClick={() => handleChangeType("h5")}>
                  <span className="icon heading-5" /> Heading 5
                </button>
                <div class="divider"></div>
                <button
                  className="item"
                  onClick={() => handleInsertList("insertUnorderedList")}
                >
                  <TbList /> Bulletlist
                </button>
                <button
                  className="item"
                  onClick={() => handleInsertList("insertOrderedList")}
                >
                  <TbListNumbers /> Numberedlist
                </button>
                <button className="item" onClick={handleInsertChecklist}>
                  <TbListCheck />
                  Checklist
                </button>
                <div class="divider"></div>
                <button
                  className="item"
                  onClick={() => handleChangeType("blockquote")}
                >
                  <BsQuote /> Quote
                </button>
                <button
                  className="item"
                  onClick={() => handleChangeType("pre")}
                >
                  <HiOutlineCodeBracket /> Codeblock
                </button>
              </div>,
              document.body
            )}
          <button onClick={toggleBold}>
            <TbBold />
          </button>
          <button onClick={toggleItalic}>
            <TbItalic />
          </button>
          <button onClick={toggleStrike}>
            <TbStrikethrough />
          </button>
          <button onClick={toggleUnderline}>
            <TbUnderline />
          </button>
          <button onClick={() => setShowTextColors(!showTextColors)}>
            <BsPaintBucket />
          </button>
          {showTextColors && (
            <ColorPalette key="text" returnColor={changeTextColor} />
          )}
          <button onClick={() => setShowHighlightColors(!showHighlightColors)}>
            <AiOutlineHighlight />
          </button>
          {showHighlightColors && (
            <ColorPalette key="highlight" returnColor={changeHighlightColor} />
          )}
          <button onClick={() => setEmIsOpen(!emisOpen)} id="emojiBtn">
            <BsEmojiSmile />
          </button>
          {emisOpen &&
            createPortal(
              <div
                style={{
                  position: "absolute",
                  top:
                    document.getElementById("emojiBtn").getBoundingClientRect()
                      .top >
                    window.innerHeight / 2
                      ? `${
                          document
                            .getElementById("emojiBtn")
                            .getBoundingClientRect().top - 440
                        }px`
                      : `${
                          document
                            .getElementById("emojiBtn")
                            .getBoundingClientRect().top + 30
                        }px`,
                  // bottom: document.getElementById("emojiBtn").getBoundingClientRect().top > (window.innerHeight/2) ? `${document.getElementById("emojiBtn").getBoundingClientRect().bottom}px` : "unset",
                  // top: `${document.getElementById("emojiBtn").getBoundingClientRect().top + 30}px`,
                  left: `${
                    document.getElementById("emojiBtn").getBoundingClientRect()
                      .left
                  }px`,
                  zIndex: "150",
                }}
              >
                {/* <Picker
                            native={true}
                            showPreview={false}
                            showSkinTones={true}
                            emojiTooltip={true}
                            autoFocus={true}
                            // emojiSize={24}
                            perLine={7}
                            color="black"
                            sheetSize={15}
                            style={{
                                position: "absolute",
                                // maxWidth: "250px",
                                maxHeight: "250px",
                                // with: "100%",
                                outline: "none",
                                // fontSize:"16px"
                            }}
                            onEmojiSelect={onSelectEmoji}
                            theme="auto"
                            skinTonePosition="search"
                        /> */}
                <EmojiPicker onEmojiSelect={onSelectEmoji} />
              </div>,
              document.body
            )}
          <button onClick={() => createLink()}>
            <TfiLink />
          </button>
          <button
            onClick={() => setShowAlignOptions(!showAlignOptions)}
            id="alignDropdown"
            className="drop-item"
          >
            <TbAlignLeft />
            <TbChevronDown />
          </button>
          {showAlignOptions &&
            createPortal(
              <div
                className="dropdown"
                style={{
                  top: `${
                    document
                      .getElementById("alignDropdown")
                      .getBoundingClientRect().top + 30
                  }px`,
                  left: `${
                    document
                      .getElementById("alignDropdown")
                      .getBoundingClientRect().left - 61
                  }px`,
                }}
              >
                <button
                  className="item"
                  onClick={() => handleAlignCommands("left")}
                >
                  <TbAlignLeft />
                </button>
                <button
                  className="item"
                  onClick={() => handleAlignCommands("center")}
                >
                  <TbAlignCenter />
                </button>
                <button
                  className="item"
                  onClick={() => handleAlignCommands("right")}
                >
                  <TbAlignRight />
                </button>
                <button
                  className="item"
                  onClick={() => handleAlignCommands("justify")}
                >
                  <TbAlignJustified />
                </button>
                <div class="divider"></div>
                <button
                  className="item"
                  onClick={() => handleIndentation("indent")}
                >
                  <TbIndentIncrease />
                </button>
                <button
                  className="item"
                  onClick={() => handleIndentation("outdent")}
                >
                  <TbIndentDecrease />
                </button>
                <div class="divider"></div>
                <button
                  className="item"
                  onClick={() => handleIndentation("insertHorizontalRule")}
                >
                  <BsFileBreak />
                </button>
              </div>,
              document.body
            )}{" "}
          <button
            onClick={() => setShowInsertOptions(!showInsertOptions)}
            className="drop-item"
          >
            <TbPlus />
            <TbChevronDown />
          </button>{" "}
          {showInsertOptions &&
            createPortal(
              <div
                className="dropdown"
                style={{
                  top: `${
                    document
                      .getElementById("alignDropdown")
                      .getBoundingClientRect().top + 30
                  }px`,
                  left: `${
                    document
                      .getElementById("alignDropdown")
                      .getBoundingClientRect().left - 11
                  }px`,
                }}
              >
                <button
                  className="item"
                  onClick={() =>
                    setShowImageOptions(!showImageOptions) &
                    setShowInsertOptions(false)
                  }
                >
                  <BsCardImage /> Image
                </button>
                <button
                  className="item"
                  onClick={() =>
                    setShowLinkVidModal(true) & setShowInsertOptions(false)
                  }
                >
                  <BsPlayBtn /> Video
                </button>
                <button className="item">
                  <TfiLayoutMediaRightAlt /> Embed
                </button>
                <div class="divider"></div>
                <button className="item" onClick={createCollapsible}>
                  <HiPaintBrush /> Drawing ..
                </button>
                <button className="item" onClick={createCollapsible}>
                  <TbSquareToggleHorizontal /> Collapsible
                </button>
                <button className="item" onClick={createTable}>
                  <BsTable /> Table
                </button>
              </div>,
              document.body
            )}
        </div>
      )}
      {showFloatingToolbar &&
        createPortal(
          <div
            className="anteditor_mini-toolbar"
            style={{
              top: `${selectionLocation?.current?.top - 85}px`,
              left: `${selectionLocation?.current?.left}px`,
            }}
          >
            <button onClick={toggleBold}>
              <TbBold />
            </button>
            <button onClick={toggleItalic}>
              <TbItalic />
            </button>
            <button onClick={() => handleChangeType("blockquote")}>
              <BsQuote />
            </button>
          </div>,
          document.body
        )}
      <div
        ref={targetNodeRef}
        id="editorr"
        contentEditable={true}
        className="anteditor_inner"
        placeholder={placeholder}
      />
      <AntListens setSpokenText={setSpokenText} />
      {showImageOptions && (
        <div className="editor_modal">
          {/* <label for="image-upload" className="admin_image-upload">
                Upload
              </label> */}
          <div
            className="editor_modal-overlay"
            onClick={() => setShowImageOptions(false)}
          ></div>
          <div className="editor_imagelink">
            <button
              className="item"
              onClick={() =>
                setShowFileImgModal(!showFileImgModal) &
                setShowInsertOptions(false) &
                setShowLinkImgModal(false)
              }
            >
              Upload File
            </button>
            <button
              className="item"
              onClick={() =>
                setShowLinkImgModal(!showLinkImgModal) &
                setShowInsertOptions(false) &
                setShowFileImgModal(false)
              }
              // onClick={createImageFromURL}
            >
              From URL
            </button>
          </div>
        </div>
      )}
      {showFileImgModal && (
        <div className="editor_modal">
          {/* <label for="image-upload" className="admin_image-upload">
                Upload
              </label> */}
          <div
            className="editor_modal-overlay"
            onClick={() => setShowFileImgModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="file"
              id="image-upload"
              name="image-upload"
              // className="userProfile__info-input"
              // onChange={(e) => handleUploadImage(e)}
              accept="image/*"
              onChange={(e) => setDocImgFile(e.target.files[0])}
            />
            <input
              type="text"
              name="alttext"
              placeholder="Alt Text"
              onChange={(e) => setDocImgFileAltText(e.target.value)}
            />
            <button onClick={onImageConfirmClick}>Insert Image</button>
          </div>
        </div>
      )}
      {showLinkImgModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowLinkImgModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="url"
              name="imagelink"
              placeholder="Image Link"
              autoFocus
              onChange={(e) => setImgFromWebLink(e.target.value)}
            />
            <input
              type="text"
              name="alttext"
              placeholder="Alt Text"
              onChange={(e) => setDocImgFileAltText(e.target.value)}
            />
            <button
              onClick={() =>
                createImageFromURL({
                  src: imgFromWebLink,
                  altText: docImgFileAltText,
                })
              }
            >
              Insert Image
            </button>
          </div>
        </div>
      )}
      {showLinkVidModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowLinkVidModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="url"
              name="videolink"
              placeholder="Youtube or Vimeo Link"
              autoFocus
              onChange={(e) => setVidFromWebLink(e.target.value)}
            />
            <button onClick={() => insertYoutube(vidFromWebLink)}>
              Insert Video
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
