import React from "react";
import { useRef } from "react";

import { useInView } from "framer-motion";

import Lottie from "lottie-react";

import WorkingTogether from "./lottie/WorkingTogether.json";
import ChatFriends from "./lottie/ChatFriends.json";

import ManagementServiceVis from "../../../assets/illustration/undraw_business_plan_re_0v81.svg";
import DesignServiceVis from "../../../assets/illustration/undraw_design_notes_re_eklr.svg";
import OnlineLearningVis from "../../../assets/illustration/undraw_online_learning_re_qw08.svg";

import { motion } from "framer-motion";

import "./consultancy.scss";
import SectionCollaboration from "./collaboration";

import images from "../images";

const ConsultancyServices = [
  {
    Header: "Project Management",
    Content:
      "If you are the type of person that wants to manage projects with human contact rather than relying solely on software solutions, we are ready to help.",
    Visual: ManagementServiceVis,
    Link: "https://www.andlogy.com/services/#project-management",
    LinkName: "Andlogy Project Management",
  },
  {
    Header: "Design Services",
    Content:
      "You may need a business or non-profit website to make a quick start. Or interior design ideas for your new shop / office, etc. We got you covered for most of your design needs. ",
    Visual: DesignServiceVis,
    Link: "https://www.andlogy.com/services/#visual-works",
    LinkName: "Andlogy Design Services",
  },
  {
    Header: "Self Improvement",
    Content:
      "You may be a DIY person. In that case, you may acquire new skills and expand your knowledge with the selective courses we release.",
    Visual: OnlineLearningVis,
    Link: "https://www.andlogy.com/courses/",
    LinkName: "Andlogy Online Courses",
  },
];

function LefttoRightFadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

function FadeInToLeft({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.4 },
      }}
    >
      {children}
    </motion.div>
  );
}

export default function SectionConsultancy() {
  const interactivity = {
    mode: "scroll",
    actions: [
      // {
      //   visibility: [0, 0.2],
      //   type: "stop",
      //   frames: [0],
      // },
      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 150],
      },
      // {
      //   visibility: [0.45, 1.0],
      //   type: "loop",
      //   frames: [120, 150],
      // },
    ],
  };

  // const LINE_VARIANTS = {
  //   visible: { height: "75vh", transition: { duration: 2 } },
  //   hidden: { height: "0vh" }
  // };

  return (
    <section id="consultancy" className="sec__services">
      <div>
        <LefttoRightFadeIn>
          <h2>Consultancy Services</h2>
          <p>
            Are you tired of managing things on your
            own or seeking professinal help? 
          </p>
          <p>
          Order custom services on our sibling platform <a href="https://www.andlogy.com/" target="_blank">
          Andlogy
                </a> in minutes.
          </p>
          {/* <p>
            Being able to visualize your future can reduce stress by providing a
            healthy outlet for emotions and worries.
          </p>
          <p>
            Antlogy provides useful integrated tools to make managing your life
            easier. So you can organize everything quickly.
          </p> */}
        </LefttoRightFadeIn>
      </div>
      <div className="sec_service-stacked">
        {ConsultancyServices.map((consultancyservice) => {
          const { Header, Content, Visual, Link, LinkName } =
            consultancyservice;
          return (
            <div className="sec_service-stacked-card" key={Header}>
              <div className="sec_service-stacked-card-inner">
                <h4>{Header}</h4>
                <FadeInWhenVisible>
                  <img src={Visual} width="100%" height="100%" />
                </FadeInWhenVisible>
              </div>
              <div className="sec_service-stacked-card-inner-content">
                <p>{Content}</p>
                <p>For more information, visit</p>
                <a href={Link} target="_blank">
                  {LinkName}
                </a>
              </div>
            </div>
          );
        })}
      </div>
      {/* <SectionCollaboration /> */}
      {/* <motion.div className="carousel">
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -600 }}
          className="inner-carousel"
        >
          {PersonalFeatures.map((personfeature) => {
            const { Header, Content, Visual } = personfeature;
            return (
              <div className="sec_feature-card">
                <div>
                  <div>
                    <h4>{Header}</h4>
                  </div>
                  <div>
                    <p>{Content}</p>
                  </div>
                </div>
                <FadeInWhenVisible>
                  <img src={Visual} width="250" height="150" />
                </FadeInWhenVisible>
              </div>
            );
          })}
        </motion.div>
      </motion.div> */}
    </section>
  );
}
