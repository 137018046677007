import React, { useState, useEffect } from "react";
import { db, fieldValue } from "../../../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import "./blogPost.single.scss";

import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import TableOfContent from "../components/tableofContents";
import SocialShareBtns from "../components/socialShareBtns";
import {
  BsFunnel,
  BsFunnelFill,
  BsSearch,
  BsChevronLeft,
  BsChevronRight,
  BsBalloonHeart,
  BsBalloonHeartFill,
} from "react-icons/bs";

const BlogPosts = [
  {
    Title: "Blog Post Header",
    Content:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium facilis iusto doloribus placeat ducimus exercitationem sunt, pariatur ab inventore sed illum tempora quis at nesciunt esse hic architecto accusamus impedit? Nisi explicabo doloremque praesentium id deleniti numquam totam inventore, ducimus exercitationem molestiae eveniet est facere fuga voluptatibus blanditiis, earum eius possimus recusandae quam deserunt laudantium? At odit iste labore aut! Distinctio nisi odit quibusdam ad consectetur. Nobis voluptatem, dolorum est quisquam labore nulla incidunt dignissimos pariatur ad distinctio quaerat atque quo ipsam repellat harum. Impedit veritatis facere aliquam rerum totam.",
    FeaturedImage:
      "https://images.pexels.com/photos/10341112/pexels-photo-10341112.jpeg",
  },
  {
    Title: "Blog Post Header Something Something 2",
    Content:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium facilis iusto doloribus placeat ducimus exercitationem sunt, pariatur ab inventore sed illum tempora quis at nesciunt esse hic architecto accusamus impedit? Nisi explicabo doloremque praesentium id deleniti numquam totam inventore, ducimus exercitationem molestiae eveniet est facere fuga voluptatibus blanditiis, earum eius possimus recusandae quam deserunt laudantium? At odit iste labore aut! Distinctio nisi odit quibusdam ad consectetur. Nobis voluptatem, dolorum est quisquam labore nulla incidunt dignissimos pariatur ad distinctio quaerat atque quo ipsam repellat harum. Impedit veritatis facere aliquam rerum totam.",
    FeaturedImage:
      "https://images.pexels.com/photos/4065876/pexels-photo-4065876.jpeg",
  },
  {
    Title: "Blog Post Header Something Something 3",
    Content:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium facilis iusto doloribus placeat ducimus exercitationem sunt, pariatur ab inventore sed illum tempora quis at nesciunt esse hic architecto accusamus impedit? Nisi explicabo doloremque praesentium id deleniti numquam totam inventore, ducimus exercitationem molestiae eveniet est facere fuga voluptatibus blanditiis, earum eius possimus recusandae quam deserunt laudantium? At odit iste labore aut! Distinctio nisi odit quibusdam ad consectetur. Nobis voluptatem, dolorum est quisquam labore nulla incidunt dignissimos pariatur ad distinctio quaerat atque quo ipsam repellat harum. Impedit veritatis facere aliquam rerum totam.",
    FeaturedImage:
      "https://images.pexels.com/photos/4065895/pexels-photo-4065895.jpeg",
  },
  {
    Title: "Blog Post Header Something Something 4",
    Content:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium facilis iusto doloribus placeat ducimus exercitationem sunt, pariatur ab inventore sed illum tempora quis at nesciunt esse hic architecto accusamus impedit? Nisi explicabo doloremque praesentium id deleniti numquam totam inventore, ducimus exercitationem molestiae eveniet est facere fuga voluptatibus blanditiis, earum eius possimus recusandae quam deserunt laudantium? At odit iste labore aut! Distinctio nisi odit quibusdam ad consectetur. Nobis voluptatem, dolorum est quisquam labore nulla incidunt dignissimos pariatur ad distinctio quaerat atque quo ipsam repellat harum. Impedit veritatis facere aliquam rerum totam.",
    FeaturedImage:
      "https://images.pexels.com/photos/4069289/pexels-photo-4069289.jpeg",
  },
];

export default function BlogPostSingle() {
  const [blog, setBlog] = useState({});
  let { slug } = useParams();
  const navigate = useNavigate();
  // const headings = document.querySelectorAll(h2, h3, h4)

  // useEffect(() => {
  //   let index = BlogPosts.findIndex(
  //     (post) => post.Title.replace(/\s+/g, "-").toLowerCase() === slug
  //   );
  //   setBlog(BlogPosts[index]);
  // }, [slug]);

  // console.log(blog);

  const PostsRef = db.collection("frontend").doc("blog").collection("posts");

  useEffect(() => {
    PostsRef.where("slug", "==", slug)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if ((slug = doc.data().slug)) {
            setBlog({ ...doc.data(), id: doc.id });
          }
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [slug]);

  const incrementLikes = () => {
    PostsRef.doc(blog.id).update({ likes: fieldValue.increment(1) });
  };
  const incrementFB = () => {
    PostsRef.doc(blog.id).update({ clickFB: fieldValue.increment(1) });
  };
  const incrementTW = () => {
    PostsRef.doc(blog.id).update({ clickTW: fieldValue.increment(1) });
  };
  const incrementLI = () => {
    PostsRef.doc(blog.id).update({ clickLI: fieldValue.increment(1) });
  };
  const incrementEM = () => {
    PostsRef.doc(blog.id).update({ clickEM: fieldValue.increment(1) });
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: "160px", opacity: 0 }}
        animate={{ x: "0px", opacity: 1 }}
        exit={{ x: "160px", opacity: 0.8 }}
        transition={{ type: "ease-in-out", duration: 0.4 }}
        className="blogpost-single-page"
      >
        <>
          <div className="blogpost-single-pagedata">
            <div className="breadcrumb-homepage">
              <button
                className="breadcrumb-back-btn"
                onClick={() => navigate(-1)}
              >
                <BsChevronLeft size="1.5em" />
              </button>{" "}
              Blog <BsChevronRight size="0.8em" /> Post Title
            </div>
            <h3>{blog?.title}</h3>
            <div className="blogpost-single-page-image">
              <img src={blog?.featuredImage} alt="" />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: blog?.content }}
              // style={{ textAlign: "left" }}
            />
            {/* <p>{blog?.content}</p> */}
          </div>

          <div className="blogarchive-sidebar">
            {" "}
            <form>
              <BsSearch size="1.4em" />
              <input type="text"></input>
            </form>
            Table of Contents
            {/* <TableOfContent /> */}
            <button onClick={() => incrementLikes()}>
              <BsBalloonHeart size="1.5em" />
              <BsBalloonHeartFill size="1.5em" />
            </button>
            <h6>Share this article</h6>
            <SocialShareBtns
              incrementFB={incrementFB}
              incrementTW={incrementTW}
              incrementLI={incrementLI}
              incrementEM={incrementEM}
            />
            <br />
            Latest Articles
          </div>
        </>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <div key={id}>
  //     <h4>{id}</h4>
  //   </div>
  // );
}
