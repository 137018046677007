import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyDU2Um3qaGfGTkNt_DGfJEGXG7vMUQQlG8",
  authDomain: "antlogy.firebaseapp.com",
  projectId: "antlogy",
  storageBucket: "antlogy.appspot.com",
  messagingSenderId: "1030243926608",
  appId: "1:1030243926608:web:67e49237fd08a36fe00755",
  databaseURL: "https://antlogy-default-rtdb.firebaseio.com/"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();

const functions = firebase.functions();

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

// Initialize Firebase Cloud Messaging and get a reference to the service
// const messaging = firebase.messaging();
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await iOS();
    if (!isSupportedBrowser) {
      return firebase.messaging();
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: `BPp9RU7K1_7ahnzJolAVd8ITYEWEvZprfFfFb2XhUTKOKHc34Lf05TeZoPy5WydY2FzsQIkQUtU4JpFrZHtX5Gc` });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

//---For Meeting Settings
export const dbMeet = firebase;

var firepadRef = firebase.database().ref();

// export const userName = prompt("What's your name?");
export const userName = "ACoolName";
// const urlparams = new URLSearchParams(window.location.search);
// const roomId = urlparams.get("id");
// const roomId = "mKXpX3ffMATbwmApThhO";

// if (roomId) {
//   firepadRef = firepadRef.child(roomId);
// } else {
//   firepadRef = firepadRef.push();
//   window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
// }

export default firepadRef;

//---Meeting Settings


// //---For Meeting Settings
// let dbRtRef = firebase.database().ref;

// // export const userName = prompt("What's your name?");
// // export const userName = "JohnDoe?";
// const urlparams = new URLSearchParams(window.location.search);
// const roomId = urlparams.get("id");
// // const roomId = "thisroom";

// if (roomId) {
//   dbRtRef = dbRtRef.child(roomId);
// } else {
//   dbRtRef = dbRtRef.push();
//   window.history.replaceState(null, "Meet", "?id=" + dbRtRef.key);
// }
// //---Meeting Settings

var provider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();
export const timestamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export var storage = firebase.storage();

const LoginWithGoogle = () => {
  auth.signInWithPopup(provider).catch((error) => {
    console.log(error.message);
  });
};

const loginWithEmailAndPassword = (email, password) => {
  auth.signInWithEmailAndPassword(email, password).catch((error) => {
    var errorMessage = error.message;
    console.log(errorMessage);
  });
};

export const registerUserFb = async (user, userName) => {
  const { uid, email, photoURL } = user;
  const userRef = await db.collection("users").doc(uid).get();
  if (!userRef.exists) {
    await db.collection("users").doc(uid).set({
      userName: userName,
      uid: uid,
      email: email,
      assignedTasks: [],
      favoriteStations: [],
      imageUrl: photoURL,
      open: true,
      timerOpen: false,
      online: true,
      plan: "Free",
      notfSettings: {
        commentAssignedMob: true,
        commentAssignedWeb: true,
        commentCreatedMob: true,
        commentCreatedWeb: true,
        meetingCancelMob: true,
        meetingCancelWeb: true,
        meetingCancelEmail: true,
        meetingMovedMob: true,
        meetingMovedWeb: true,
        meetingMovedEmail: true,
        meetingNewMob: true,
        meetingNewWeb: true,
        meetingNewEmail: true,
        meetingStartMob: true,
        meetingStartWeb: true,
        meetingStartEmail: true,
        noteAssignedMob: true,
        noteAssignedWeb: true,
        privateMsgMob: true,
        privateMsgWeb: true,
        projectMsgMob: true,
        projectMsgWeb: true,
        taskAssignedMob: true,
        taskAssignedWeb: true,
        taskEndMob: true,
        taskEndWeb: true,
        taskStartMob: true,
        taskStartWeb: true,
        workspaceMsgMob: true,
        workspaceMsgWeb: true,
      }
    });
    const userImage = await db.collection("users").doc(uid).get();
    const userImageUrl = userImage.data().imageUrl;
    if (userImageUrl === null) {
      db.collection("users").doc(uid).set(
        {
          imageUrl:
            "https://www.reshot.com/preview-assets/icons/AVQMW34BS2/snow-AVQMW34BS2.svg",
        },
        { merge: true }
      );
    }
  } else {
    console.log("no code");
  }
};

const createNewSpace = async (name, currentUser, color, setLayer) => {
  const { uid } = currentUser;
  if (!name) {
    alert("Workspace name is required");
    return;
  }

  await db
    .collection("space")
    .add({
      name: name,
      admin: uid,
      color: color,
      members: firebase.firestore.FieldValue.arrayUnion(uid),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      description: "",
    })
    .then((data) => {
      let id = data.id;
      db.collection("space").doc(id).set(
        {
          spaceId: id,
        },
        { merge: true }
      );
    });

  if (setLayer) {
    setLayer(false);
  }
};

export const createNewStationn = async (
  spaceId,
  stationName,
  statusType,
  statusOrder,
  modules
) => {
  let stationId = "123";
  const stationsRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations");

  await stationsRef
    .add({
      name: stationName,
      description: "Add description",
      fromSpaceId: spaceId,
      emojicon: "◾",
      folder: null,
      members: firebase.firestore.FieldValue.arrayUnion(),
    })
    .then((data) => {
      let id = data.id;
      stationId = id;
      stationsRef.doc(id).set(
        {
          stationId: id,
        },
        { merge: true }
      );
      // var batch = db.batch();
      // array.forEach((doc) => {
      //   var docRef = stationsRef.doc("getIDOFSTATIONfirst").collection("phases").doc(); //automatically generate unique id
      //   batch.set(docRef, doc);
      // });
      // stationsRef.doc(id).collection("phases").doc("?").set({
      //   // tasks: null,
      //   statusType,
      //   statusOrder,
      // });
      stationsRef.doc(id).collection("modules").doc("modules").set({
        modules,
      });
    });
  return stationId;
};
export const createNewStation2 = async (
  spaceId,
  stationName,
  phases,
  phaseOrder,
  modules,
  startDate,
  endDate,
  emoji
) => {
  let stationId = "123";
  const stationsRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations");

  await stationsRef
    .add({
      name: stationName,
      description: "Add description",
      fromSpaceId: spaceId,
      emojicon: emoji,
      folder: null,
      startdate: startDate,
      enddate: endDate,
      members: firebase.firestore.FieldValue.arrayUnion(),
    })
    .then((data) => {
      let id = data.id;
      stationId = id;
      stationsRef.doc(id).set(
        {
          stationId: id,
        },
        { merge: true }
      );

      stationsRef.doc(id).set({
        phaseOrder,
      }, { merge: true });

      var batch = db.batch();
      const phasevalues = Object.values(phases);
      phasevalues.forEach((doc) => {
        batch.set(stationsRef.doc(id).collection("phases").doc(doc.id), doc);
      });
      // Commit the batch
      batch.commit().then(function () {
        console.log("project phases created successfully")// ...
      });

      // stationsRef.doc(id).collection("phases").doc("phases").set({
      //   phases,
      //   phaseOrder,
      // });
      // var batch = db.batch();
      // phases.forEach((phase) => {
      //   var docRef = stationsRef.doc(id).collection("phases").doc(); //automatically generate unique id
      //   batch.set(docRef, { ...phase });
      // });
      // batch.commit();


      // batch.commit();

      // stationsRef.doc(id).collection("phases").doc("phases").set({
      //   phases,
      //   phaseOrder,
      // });

      // var batch = db.batch()
      // phases.forEach((doc) => {
      //   var docRef = db
      //     .collection("space")
      //     .doc(spaceId)
      //     .collection("stations").doc(id).collection("phases").doc(doc.name); //automatically generate unique id
      //   batch.set(docRef, { color: doc.color, fontColor: doc.color, id: doc.id, name: doc.name, taskIds: doc.taskIds });
      // });
      // batch.commit();



      // let myMap = new Map().set('a', 1).set('b', 2);
      // let array = Array.from(myMap, ([name, value]) => ({ name, value }));
      // console.log(array);

      // spreadphases = [...phases]
      // const spreadphases = Array.from(phases, ([name, value]) => ({ name, value }))

      // console.log("spreadphases:", spreadphases)
      // const phasenames = Object.keys(phases);


      // var batch = db.batch()
      // statusType.forEach((doc) => {
      //   var docRef = stationsRef.doc(id).collection("phases").doc(); //automatically generate unique id
      //   batch.set(docRef, doc);
      // });
      // batch.commit();

      // stationsRef.doc(id).collection("phases").add({
      //   statusType,
      //   statusOrder,
      // });
      stationsRef.doc(id).collection("modules").doc("modules").set({
        modules,
      });
    });
  return stationId;
};
export const createNewStationDemo = async (
  spaceId,
  stationName,
  statusType,
  statusOrder,
  modules
) => {
  let stationId = "123";
  const stationsRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc("demoStation0");

  await stationsRef
    .set({
      name: stationName,
      description: "Add description",
      fromSpaceId: spaceId,
      stationId: "demoStation0",
    })
    .then(() => {
      stationsRef.collection("tasks").doc("tasks").set({
        tasks: null,
        statusType,
        statusOrder,
      });
      stationsRef.collection("modules").doc("modules").set({
        modules,
      });
    });
  return stationId;
};

export const createNewFolder = async (
  spaceId,
  folderName,
  folderContent,
  folderOrder
) => {
  let folderId = "123";
  const foldersRef = db
    .collection("space")
    .doc(spaceId)
    .collection("folders");

  await foldersRef
    .add({
    })
    .then((data) => {
      let id = data.id;
      folderId = id;
      foldersRef.doc(id).set(
        {
          folderId: id,
        },
        { merge: true }
      );
      foldersRef.doc(id).set({
        name: folderName,
        fromSpaceId: spaceId,
        emojicon: "📁",
        folderContent: [],
        folderOrder,
      });
    });
  return folderId;
};


export const createNewTaskk = async (
  spaceId,
  stationId,
  statusId,
  newTaskName,
  userId
) => {
  const newTaskAdded = await db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .add({
      title: newTaskName,
      description: null,
      done: false,
      startdate: null,
      enddate: null,
      duration: "00:30",
      allDay: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId,
      fromSpaceId: spaceId,
      fromStationId: stationId,
      assign: null,
      phase: statusId,
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(65, 163, 0)" },
      ],
      progress: 0,
      rrule: null,
      subtasks: [],
      type: "task",
    });
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(newTaskAdded.id)
    .update({ id: newTaskAdded.id });
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId)
    .update({
      taskIds: firebase.firestore.FieldValue.arrayUnion(newTaskAdded.id),
    });
};

export const createNewInboxTask = async (
  userId,
  newTaskName,
  rrule
) => {
  const newTaskAdded = await db.collection("users")
    .doc(userId)
    .collection("tasks")
    .add({
      title: newTaskName,
      description: null,
      done: false,
      startdate: null,
      enddate: null,
      duration: "00:30",
      allDay: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId,
      fromSpaceId: `${userId}-Inbox`,
      fromStationId: "",
      assign: userId,
      phase: "Inbox",
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(65, 163, 0)" },
      ],
      progress: 0,
      rrule: rrule,
      subtasks: [],
      type: "task",
    });
  db.collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(newTaskAdded.id)
    .update({ id: newTaskAdded.id });
  db.collection("users")
    .doc(userId)
    .collection("taskinbox")
    .doc("taskinbox")
    .update({
      taskIds: firebase.firestore.FieldValue.arrayUnion(newTaskAdded.id),
    });
};

export const createNewInboxTaskPlus = async (
  userId, title, description, startDate, endDate, type, allday, duration, progress, rrule
) => {
  const newTaskAdded = await db.collection("users")
    .doc(userId)
    .collection("tasks")
    .add({
      title: title,
      description: description,
      done: false,
      startdate: startDate,
      enddate: endDate,
      duration: duration,
      allDay: allday,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: userId,
      fromSpaceId: `${userId}-Inbox`,
      fromStationId: "",
      assign: userId,
      phase: "Inbox",
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(65, 163, 0)" },
      ],
      progress: progress,
      rrule: rrule,
      subtasks: [],
      type: type,
    });
  db.collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(newTaskAdded.id)
    .update({ id: newTaskAdded.id });
  db.collection("users")
    .doc(userId)
    .collection("taskinbox")
    .doc("taskinbox")
    .update({
      taskIds: firebase.firestore.FieldValue.arrayUnion(newTaskAdded.id),
    });
};

export const createNewTask = async (
  spaceId,
  stationId,
  statusName,
  title,
  description,
  userId,
  startDate,
  endDate,
  type,
  allday,
  duration,
  progress,
  rrule
) => {
  const newTaskAdded = await db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .add({
      title: title,
      description: description,
      done: false,
      startdate: startDate,
      enddate: endDate,
      duration: duration,
      allDay: allday,
      createdAt: fieldValue.serverTimestamp(),
      createdBy: userId,
      fromSpaceId: spaceId,
      fromStationId: stationId,
      assign: null,
      phase: statusName,
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(65, 163, 0)" },
      ],
      progress: progress,
      rrule: rrule,
      subtasks: [],
      type: type,
    });
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(newTaskAdded.id)
    .update({ id: newTaskAdded.id });
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusName)
    .update({
      taskIds: fieldValue.arrayUnion(newTaskAdded.id),
    });
  db.collection("users")
    .doc(userId)
    .collection("taskinbox")
    .doc("taskinbox")
    .update({
      taskIds: firebase.firestore.FieldValue.arrayUnion(newTaskAdded.id),
    });
};

export const createNewTaskOld = async (
  spaceId,
  stationId,
  statusName,
  newTaskName,
  userId
) => {
  const docRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");
  const getData = await docRef.get();
  const data = getData.data();
  const { statusType, tasks } = data;

  // TASKS
  let v4 = uuidv4();
  let newTask = {
    [v4]: {
      id: v4,
      content: newTaskName,
      createdBy: userId,
      assign: null,
      created: new Date(),
      timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      estDuration: "",
      startdate: null,
      deadline: null,
      fromSpaceId: spaceId,
      fromStationId: stationId,
      message: [],
      description: "Add description",
      done: false,
      allDay: true,
      subtasks: [],
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(65, 163, 0)" },
      ],
    },
  };
  let newTasks = {
    ...tasks,
    ...newTask,
  };

  // STATUS TYPE

  let taskIds = statusType[statusName].taskIds;

  taskIds.push(v4);

  const newData = {
    ...data,
    statusType: {
      ...statusType,
      [statusName]: {
        ...data.statusType[statusName],
        taskIds: taskIds,
      },
    },
    tasks: newTasks,
  };
  docRef.set({
    ...newData,
  });
};

export const deleteTask = (spaceId,
  stationId,
  phase,
  userId,
  task) => {
  if (spaceId === `${userId}-Inbox`) {
    db.collection("users")
      .doc(userId)
      .collection("tasks")
      .doc(task.id)
      .delete();
  } else {
    db.collection("space")
      .doc(spaceId)
      .collection("stations")
      .doc(stationId)
      .collection("tasks")
      .doc(task.id)
      .delete();
    db.collection("space")
      .doc(spaceId)
      .collection("stations")
      .doc(stationId)
      .collection("phases")
      .doc(phase)
      .update({
        taskIds: fieldValue.arrayRemove(task.id),
      });

    //Delete Comments
    db.collection("space")
      .doc(spaceId)
      .collection("stations")
      .doc(stationId)
      .collection("tasks")
      .doc(task.id)
      .collection("comments")
      .get()
      .then(function (querySnapshot) {
        // Once we get the results, begin a batch
        var batch = db.batch();

        querySnapshot.forEach(function (doc) {
          // For each doc, add a delete operation to the batch
          batch.delete(doc.ref);
        });

        // Commit the batch
        return batch.commit();
      })
      .then(function () {
        // Delete completed!
        // ...
      });
  }
  if (task.assign) {
    const assignedUserRef = db.collection("users").doc(task.assign);
    assignedUserRef.get().then((userRefData) => {
      let assignedTasks = userRefData.data().assignedTasks;
      const findIndex = assignedTasks.findIndex((item) => item.id === task.id);
      assignedUserRef.update({
        assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
      });
    });
  }
};

// export const moveTask = (sourceSpaceId,
//   targetSpaceId,
//   sourceStationId,
//   targetStationId,
//   sourcePhase,
//   targetPhase,
//   userId,
//   task) => {
//     db.collection("space")
//     .doc(targetSpaceId)
//     .collection("stations")
//     .doc(targetStationId)
//     .collection("tasks")
//     .doc(task.id)
// };

export const createNewSubtask = async (spaceId, stationId, taskId, content) => {
  let v4 = uuidv4();

  const docRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId);

  const getRef = await docRef.get();
  const data = getRef.data();

  const newSubtask = {
    id: v4,
    created: new Date(),
    content: content,
    done: false,
    fromSpaceId: spaceId,
    fromStationId: stationId,
    fromTaskId: taskId,
  };

  // const newData = {
  //   ...data,
  //   subtasks: [...data.subtasks, newSubtask],
  // };

  //   docRef.set({
  //     ...newData,
  //   });

  docRef.update({
    subtasks: fieldValue.arrayUnion(newSubtask),
  });
};
// export const createNewSubtaskk = async (spaceId, stationId, taskId, content) => {
//   let v4 = uuidv4();

//   const docRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   const getRef = await docRef.get();
//   const data = getRef.data();

//   const newSubtask = {
//     id: v4,
//     created: new Date(),
//     content: content,
//     done: false,
//     fromSpaceId: spaceId,
//     fromStationId: stationId,
//     fromTaskId: taskId,
//   };

//   const newData = {
//     ...data,
//     tasks: {
//       ...data.tasks,
//       [taskId]: {
//         ...data.tasks[taskId],
//         subtasks: [...data.tasks[taskId].subtasks, newSubtask],
//       },
//     },
//   };

//   docRef.set({
//     ...newData,
//   });
// };

export const updateSubDrag = (spaceId, stationId, taskId, newData) => {
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .update({
      subtasks: [...newData],
    });
};
// export const updateSubDrag = (spaceId, stationId, taskId, newData) => {
//   let task = {};
//   let tasks = {};
//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       task = taskData.data().tasks[taskId];
//       tasks = taskData.data().tasks;
//       console.log(taskData.data());
//       tasks = {
//         ...tasks,
//         [taskId]: {
//           ...task,
//           subtasks: [...newData],
//         },
//       };
//     })
//     .then(() => {
//       tasksRef.update({
//         tasks: {
//           ...tasks,
//         },
//       });
//     });
// };

export const updateTaskAsDone = async (
  spaceId,
  stationId,
  taskId,
  done
) => {
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .update({
      done: done
    })
};

export const updateInboxTaskAsDone = async (
  userId,
  taskId,
  done
) => {
  db
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(taskId)
    .update({
      done: done
    })
};

export const updateSubTaskAsDone = async (
  spaceId,
  stationId,
  taskId,
  subId,
  done
) => {
  let tasks = {};
  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId);

  tasksRef
    .get()
    .then((taskData) => {
      // task = taskData.data().tasks[taskId];
      // tasks = taskData.data().tasks;
      // let subtasks = task.subtasks;
      let subtasks = taskData.data().subtasks;
      const findIndex = subtasks.findIndex((item) => item.id === subId);
      subtasks[findIndex].done = !subtasks[findIndex].done;
      console.log(subtasks);
      /*     const toogleSubDone = !subDone;
    console.log(toogleSubDone); */
      tasks = [...subtasks]
    })
    .then(() => {
      tasksRef.update({
        subtasks: tasks,
      });
    });
};
export const deleteSubTask = async (
  spaceId,
  stationId,
  taskId,
  subId,
  done
) => {
  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId);

  tasksRef
    .get()
    .then((taskData) => {
      let subtasks = taskData.data().subtasks;
      const findIndex = subtasks.findIndex((item) => item.id === subId);
      tasksRef.update({ subtasks: fieldValue.arrayRemove(subtasks[findIndex]) })
    })
};
// export const updateSubTaskAsDone = async (
//   spaceId,
//   stationId,
//   taskId,
//   subId,
//   done
// ) => {
//   let task = {};
//   let tasks = {};
//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       task = taskData.data().tasks[taskId];
//       tasks = taskData.data().tasks;
//       let subtasks = task.subtasks;
//       const findIndex = subtasks.findIndex((item) => item.id === subId);
//       subtasks[findIndex].done = !subtasks[findIndex].done;
//       console.log(subtasks);
//       /*     const toogleSubDone = !subDone;
//     console.log(toogleSubDone); */
//       tasks = {
//         ...tasks,
//         [taskId]: {
//           ...tasks[taskId],
//           subtasks: [...subtasks],
//         },
//       };
//     })
//     .then(() => {
//       tasksRef.update({
//         tasks: {
//           ...tasks,
//         },
//       });
//     });
// };

export const createNewTaskDemo = async (
  statusName,
  newTaskName,
  userId,
  assign,
  startdate,
  deadline,
  done,
  priority
) => {
  const docRef = db
    .collection("space")
    .doc("dummySpace0")
    .collection("stations")
    .doc("demoStation0")
    .collection("tasks")
    .doc("tasks");
  const getData = await docRef.get();
  const data = getData.data();
  const { statusType, tasks } = data;

  // TASKS
  let v4 = uuidv4();
  let newTask = {
    [v4]: {
      id: v4,
      content: newTaskName,
      createdBy: userId,
      assign: assign,
      created: new Date(),
      timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      startdate: startdate,
      deadline: deadline,
      fromSpaceId: "dummySpace0",
      fromStationId: "demoStation0",
      message: [],
      description: "Add description",
      done: done,
      priority: priority,
      subtasks: [],
    },
  };
  let newTasks = {
    ...tasks,
    ...newTask,
  };

  // STATUS TYPE

  let taskIds = statusType[statusName].taskIds;

  taskIds.push(v4);

  const newData = {
    ...data,
    statusType: {
      ...statusType,
      [statusName]: {
        ...data.statusType[statusName],
        taskIds: taskIds,
      },
    },
    tasks: newTasks,
  };
  docRef.set({
    ...newData,
  });
};

export const updateDrag = (spaceId, stationId, phaseId, newTaskIds) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(phaseId)
    .update({
      taskIds: [...newTaskIds],
    });
};
export const updateColumnDrag = (spaceId, stationId, newStatusOrder) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      phaseOrder: newStatusOrder,
    });
};
// export const updateDrag = (spaceId, stationId, newState) => {
//   db.collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks")
//     .set({
//       ...newState,
//     });
// };
export const updateFolderDrag = (spaceId, folderId, newState) => {
  db.collection("space")
    .doc(spaceId)
    .collection("folders")
    .doc(folderId)
    .set({
      ...newState,
    });
};

//NOTES

export const addNote = (spaceId, stationId, title,
  text,
  url,
  isPinned,
  isArchived,
  color,
  collaborators,
  reminder,
  labels,
  lastUpdater, privacy) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .add({
      url: url,
      title: title,
      text: text,
      createdAt: fieldValue.serverTimestamp(),
      isPinned: isPinned,
      isArchived: isArchived,
      labels: labels,
      color: color,
      collaborators: collaborators,
      reminder: reminder,
      lastUpdater: lastUpdater,
      updatedAt: fieldValue.serverTimestamp(),
      privacy: privacy
    });
};

export const deleteNote = (spaceId, stationId, note) => {
  if (note.url) {
    storage.refFromURL(note.url).delete();
  }
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(note.id)
    .delete();
};


export const updateNoteImage = (spaceId, stationId, noteId, imageUrl) => {
  //   db.collection("space")
  //     .doc(spaceId)
  //     .collection("stations")
  //     .doc(stationId)
  //     .collection("notes")
  //     .doc("noteId")
  //     .update({
  //       url: imageUrl
  //     });
};

export const updateNoteTitle = (spaceId, stationId, noteId, title) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      title: title
    });
};

export const updateNoteText = (spaceId, stationId, noteId, text, lastUpdater) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      text: text,
      lastUpdater: lastUpdater,
      updatedAt: fieldValue.serverTimestamp()
    });
};

export const updateNotePrivacy = (spaceId, stationId, noteId, privacy, lastUpdater) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      private: privacy,
      lastUpdater: lastUpdater,
      updatedAt: fieldValue.serverTimestamp(),
    });
};

export const updateNoteColor = (spaceId, stationId, noteId, color) => {
  //   db.collection("space")
  //     .doc(spaceId)
  //     .collection("stations")
  //     .doc(stationId)
  //     .collection("notes")
  //     .doc("noteId")
  //     .update({
  //       color: color
  //     });
};

export const pinNote = (spaceId, stationId, noteId, pinStatus) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      isPinned: pinStatus
    });
};

export const archiveNote = (spaceId, stationId, noteId, archiveStatus) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      isArchived: archiveStatus,
    });
};

export const addNoteLabel = (spaceId, stationId, noteId, label) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      labels: fieldValue.arrayUnion(label)
    });
};

export const deleteNoteLabel = (spaceId, stationId, noteId, label) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("notes")
    .doc(noteId)
    .update({
      labels: fieldValue.arrayRemove(label)
    });
};

//JOURNAL RELATED
export const updateJournalDay = (userId, date, content) => {
  db.collection("users")
    .doc(userId)
    .collection("journals")
    .doc(date)
    .set(
      {
        journal: content,
      },
      { merge: true }
    );
};

//WORKSPACE RELATED
export const updateWorkspaceBrief = (spaceId, content, lastUpdater) => {
  db.collection("space")
    .doc(spaceId)
    .update({
      description: content,
      // lastUpdater: lastUpdater,
      // updatedAt: fieldValue.serverTimestamp()
    });
};

export const removeMember = (spaceId, memberId) => {
  db.collection("space")
    .doc(spaceId)
    .update({
      members: firebase.firestore.FieldValue.arrayRemove(memberId),
    });
};

export const removeProjectMember = (spaceId, stationId, memberId, sharedProjectRef) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      members: fieldValue.arrayRemove(memberId),
    })
    .then(() => {
      db.collection("users")
        .doc(memberId)
        .update({
          sharedWith: fieldValue.arrayRemove(sharedProjectRef),
        });
    })
};

export const newAdmin = (spaceId, memberId) => {
  db.collection("space").doc(spaceId).update({
    admin: memberId,
  });
};

export const setSpaceAsFavorite = (userId, spaceId) => {
  db.collection("users").doc(userId).update({
    favoriteSpace: spaceId,
  });
};

export const getFavoriteStations = async (favoriteSpaceId) => {
  if (!favoriteSpaceId) return;
  const colRef = db
    .collection("space")
    .doc(favoriteSpaceId)
    .collection("stations");
  const querySnapshop = await colRef.get();
  if (querySnapshop.empty) {
    console.log("empity");

    let list = [];
    querySnapshop.forEach((data) => {
      list.push(data.data());
    });
    return list;
  }
};

export const renameStation = (spaceId, stationId, newName) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      name: newName,
    });
};

export const renameSpace = (spaceId, newName) => {
  db.collection("space").doc(spaceId).update({
    name: newName,
  });
};

export const deleteSpace = (spaceId) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .get()
    .then((stationQuery) => {
      if (!stationQuery.empty) {
        stationQuery.forEach((stationDoc) => {
          db.collection("space")
            .doc(spaceId)
            .collection("stations")
            .doc(stationDoc.id)
            .collection("tasks")
            .doc("tasks")
            .delete();
          db.collection("space")
            .doc(spaceId)
            .collection("stations")
            .doc(stationDoc.id)
            .collection("modules")
            .doc("modules")
            .delete();
          stationDoc.ref.delete();
        });
      }
    })
    .then(() => {
      db.collection("space").doc(spaceId).delete();
    });
};

export const deleteStation = async (spaceId, stationId) => {
  await db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("modules")
    .doc("modules")
    .delete();

  await db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks")
    .collection("comments")
    .get()
    .then((query) => {
      query.forEach((comment) => {
        db.collection("space")
          .doc(spaceId)
          .collection("stations")
          .doc(stationId)
          .collection("tasks")
          .doc("tasks")
          .collection("comments")
          .doc(comment.id)
          .delete();
      });
    })
    .then(() => {
      db.collection("space")
        .doc(spaceId)
        .collection("stations")
        .doc(stationId)
        .collection("tasks")
        .doc("tasks")
        .delete();

      db.collection("space")
        .doc(spaceId)
        .collection("stations")
        .doc(stationId)
        .delete();
    });
};

export const updateColorOfSpace = (spaceId, color) => {
  db.collection("space").doc(spaceId).update({
    color: color,
  });
};

export const changeDescriptionOfSpace = (spaceId, newDesc) => {
  db.collection("space").doc(spaceId).update({
    description: newDesc,
  });
};

export const changeDescriptionOfStation = (spaceId, stationId, newDesc) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      description: newDesc,
    });
};
export const changeNameOfStation = (spaceId, stationId, newName) => {
  console.log(spaceId, stationId, newName);
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      name: newName,
    });
};
export const changeEmojiconOfStation = (spaceId, stationId, newEmo) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      emojicon: newEmo,
    });
};
// export const changeEmojiReactionOfMessage = (spaceId, newEmo) => {
//   db.collection("space")
//     .doc(spaceId)
//     .update({
//       emojicon: newEmo,
//     });
// };

export const updateTaskName = (fromSpaceId, fromStationId, currentUserId, taskId, title) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        title: title,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        title: title,
      });
  }
};
export const updateTaskDone = (fromSpaceId, fromStationId, currentUserId, taskId, taskdone) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        done: taskdone,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        done: taskdone,
      });
  }
};
export const updateTaskDescription = (fromSpaceId, fromStationId, currentUserId, taskId, description) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        description: description,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        description: description,
      });
  }
};
export const updateTaskAllDay = (fromSpaceId, fromStationId, currentUserId, taskId, allDay) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        allDay: allDay,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        allDay: allDay,
      });
  }
};

export const updateTaskDuration = (fromSpaceId, fromStationId, currentUserId, taskId, duration) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        duration: duration,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        duration: duration,
      });
  }
};

export const updateTaskRrule = (fromSpaceId, fromStationId, currentUserId, taskId, rrule) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        rrule: rrule,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        rrule: rrule,
      });
  }
};

export const updateTaskMilestone = (fromSpaceId, fromStationId, currentUserId, taskId, taskType) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        type: taskType,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        type: taskType,
      });
  }
};

export const updateTaskStart = (fromSpaceId, fromStationId, currentUserId, taskId, startDate) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        startdate: startDate,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        startdate: startDate,
      });
  }
};

export const updateTaskEnd = (fromSpaceId, fromStationId, currentUserId, taskId, endDate) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        enddate: endDate,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        enddate: endDate,
      });
  }
};

export const updateTaskProgress = (fromSpaceId, fromStationId, currentUserId, taskId, progress) => {
  if (fromSpaceId === `${currentUserId}-Inbox`) {
    db.collection("users")
      .doc(currentUserId)
      .collection("tasks")
      .doc(taskId)
      .update({
        progress: progress,
      });
  } else {
    db.collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc(taskId)
      .update({
        progress: progress,
      });
  }
};

export const changeTaskName = async (spaceId, stationId, newName, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        content: newName,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const updateModulesDb = (spaceId, stationId, module, modules) => {
  let objIndex = modules.findIndex((item) => item.name === module.name);
  modules[objIndex].active = !modules[objIndex].active;
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("modules")
    .doc("modules")
    .set({
      modules,
    });
};

export const getUserDataWithId = async (userId) => {
  const userRef = db.collection("users").doc(userId);
  const userData = await userRef.get();
  const data = userData.data();
  return data;
};

// export const updateUser = (userId, user) => {
//   const userRef = db.collection("users").doc(userId);
//   userRef.update({
//     ...user,
//   });
// };


// export const personAssignNotify = (spaceId, stationId, taskId, userId, currentUserName) => {
//   db.collection("users").doc(userId).collection("pnotifications").add({
//     fromSpaceId: spaceId,
//     fromStationId: stationId,
//     taskId: taskId,
//     from: currentUserName,
//     type: "assign",
//     seen: false,
//     created: fieldValue.serverTimestamp(),
//   });
// };

// export const personCommentNotify = (spaceId, stationId, taskId, userId, currentUserName) => {
//   db.collection("users").doc(userId).collection("pnotifications").add({
//     fromSpaceId: spaceId,
//     fromStationId: stationId,
//     taskId: taskId,
//     from: currentUserName,
//     type: "comment",
//     seen: false,
//     created: fieldValue.serverTimestamp(),
//   });
// };

export const personNotfUpdateSeen = (userId, notificationId) => {
  const notificationRef = db.collection("users").doc(userId).collection("pnotifications").doc(notificationId);
  notificationRef.update({
    seen: true,
  });
};
export const personNotfUpdateSeenAll = (userId) => {
  db.collection("users").doc(userId).collection("pnotifications").where("seen", "==", false).get().then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      doc.ref.update({
        seen: true
      });
    });
  });
};

export const personNotfUpdateDeleteAll = (userId) => {
  //DELETE THE NOTIFICATIONS WITHIN THE COLLECTION
  // First perform the query
  db.collection("users").doc(userId).collection("pnotifications").get()
    .then(function (querySnapshot) {
      // Once we get the results, begin a batch
      var batch = db.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    }).then(function () {
      // Delete completed!
      // ...
    });

};

export const assignMember = (spaceId, stationId, taskId, userId, lastUpdater) => {
  // let allTasks = [];
  // let task = [];
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .update({
      assign: userId,
      lastUpdater: lastUpdater,
    })

  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     allTasks = taskData.data().tasks;
  //     task = taskData.data().tasks[taskId];
  //     task = {
  //       ...task,
  //       assign: userId,
  //     };
  //   })
  //   .then(() => {
  //     tasksRef.set(
  //       {
  //         tasks: {
  //           ...allTasks,
  //           [taskId]: {
  //             ...task,
  //           },
  //         },
  //       },
  //       { merge: true }
  //     );
  //   });
};
// export const assignMember = (spaceId, stationId, taskId, userId) => {
//   let allTasks = [];
//   let task = [];

//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       allTasks = taskData.data().tasks;
//       task = taskData.data().tasks[taskId];
//       task = {
//         ...task,
//         assign: userId,
//       };
//     })
//     .then(() => {
//       tasksRef.set(
//         {
//           tasks: {
//             ...allTasks,
//             [taskId]: {
//               ...task,
//             },
//           },
//         },
//         { merge: true }
//       );
//     });
// };

export const unAssign = (spaceId, stationId, taskId) => {
  // let task = {};
  // let tasks = {};
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .update({
      assign: null,
    })

  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     task = taskData.data().tasks[taskId];
  //     tasks = taskData.data().tasks;
  //     console.log(taskData.data());
  //     tasks = {
  //       ...tasks,
  //       [taskId]: {
  //         ...task,
  //         assign: [],
  //       },
  //     };
  //   })
  //   .then(() => {
  //     console.log(tasks);
  //     tasksRef.update({
  //       tasks: {
  //         ...tasks,
  //       },
  //     });
  //   });
};
// export const unAssign = (spaceId, stationId, taskId) => {
//   let task = {};
//   let tasks = {};
//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       task = taskData.data().tasks[taskId];
//       tasks = taskData.data().tasks;
//       console.log(taskData.data());
//       tasks = {
//         ...tasks,
//         [taskId]: {
//           ...task,
//           assign: [],
//         },
//       };
//     })
//     .then(() => {
//       console.log(tasks);
//       tasksRef.update({
//         tasks: {
//           ...tasks,
//         },
//       });
//     });
// };

export const setPriority = (
  spaceId,
  stationId,
  taskId,
  allPriority,
  priority
) => {
  // let allTasks = [];
  // let task = [];

  // set all false
  allPriority.map((item) => (item.active = false));

  // set active on clicked element
  priority.active = true;

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .update({ priority: [...allPriority] })

  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     allTasks = taskData.data().tasks;
  //     task = taskData.data().tasks[taskId];
  //     task = {
  //       ...task,
  //       priority: [...allPriority],
  //     };
  //   })
  //   .then(() => {
  //     tasksRef.set(
  //       {
  //         tasks: {
  //           ...allTasks,
  //           [taskId]: {
  //             ...task,
  //           },
  //         },
  //       },
  //       { merge: true }
  //     );
  //   });
};
// export const setPriority = (
//   spaceId,
//   stationId,
//   taskId,
//   allPriority,
//   priority
// ) => {
//   let allTasks = [];
//   let task = [];

//   // set all false
//   allPriority.map((item) => (item.active = false));

//   // set active on clicked element
//   priority.active = true;

//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       allTasks = taskData.data().tasks;
//       task = taskData.data().tasks[taskId];
//       task = {
//         ...task,
//         priority: [...allPriority],
//       };
//     })
//     .then(() => {
//       tasksRef.set(
//         {
//           tasks: {
//             ...allTasks,
//             [taskId]: {
//               ...task,
//             },
//           },
//         },
//         { merge: true }
//       );
//     });
// };

export const setStatus = (
  spaceId,
  stationId,
  currentStatusType,
  taskId,
  status,
  statusType
) => {
  let removedStatus = {};

  console.log(statusType);

  // remove taskId from current
  let removeCurrentId = currentStatusType.taskIds.filter((id) => id !== taskId);

  removedStatus = currentStatusType.taskIds = removeCurrentId;
  console.log(removedStatus[0]);

  // add taskId to new

  statusType[status.name] = {
    ...status,
    taskIds: [...status.taskIds, taskId],
  };

  console.log(statusType);

  // set taskIds

  const statusTypeRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  statusTypeRef.set(
    {
      statusType: { ...statusType },
    },
    { merge: true }
  );
};

export const convertDate = (timestamp) => {
  if (!timestamp) {
    return;
  }

  let myTime = timestamp.toDate();
  let date = myTime.getDate();
  let month = myTime.getMonth();
  let year = myTime.getFullYear();
  return `${date}.${month + 1}`;
};

export const setStartDate = (spaceId, stationId, date, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        startdate: date,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const setDeadlineDate = (spaceId, stationId, date, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        deadline: date,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};
export const setTimeTask = async (spaceId, stationId, time, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  await tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        time: time,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

// export const changeStatusTypeName = (
//   spaceId,
//   stationId,
//   statusName,
//   newName,
//   statusTypeCheck
// ) => {
//   let keys = Object.keys(statusTypeCheck);
//   if (statusName === newName) {
//     alert("same name");
//     return;
//   }
//   if (keys.includes(newName)) {
//     alert("name is already takaen");
//     return;
//   }

//   let statusOrder = [];
//   let statusType = {};

//   const tasksRef = db
//     .collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("tasks")
//     .doc("tasks");

//   tasksRef
//     .get()
//     .then((taskData) => {
//       statusOrder = taskData.data().statusOrder;
//       let indexStatus = statusOrder.findIndex((item) => item === statusName);
//       statusOrder[indexStatus] = newName;
//       console.log(statusOrder);

//       statusType = taskData.data().statusType;

//       statusType = {
//         ...statusType,
//         [statusName]: {
//           ...statusType[statusName],
//           name: newName,
//           id: newName,
//         },
//       };
//       statusType[newName] = {
//         ...statusType[statusName],
//       };
//       delete statusType[statusName];
//     })
//     .then(() => {
//       tasksRef.update({
//         statusOrder,
//         statusType,
//       });
//     });
// };
export const changeStatusTypeName = (
  spaceId,
  stationId,
  statusName,
  newName,
  statusTypeCheck
) => {
  let keys = Object.keys(statusTypeCheck);
  if (statusName === newName) {
    alert("same name");
    return;
  }
  if (keys.includes(newName)) {
    alert("name is already takaen");
    return;
  }

  let statusOrder = [];
  let statusType = {};

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      statusOrder = taskData.data().statusOrder;
      let indexStatus = statusOrder.findIndex((item) => item === statusName);
      statusOrder[indexStatus] = newName;
      console.log(statusOrder);

      statusType = taskData.data().statusType;

      statusType = {
        ...statusType,
        [statusName]: {
          ...statusType[statusName],
          name: newName,
          id: newName,
        },
      };
      statusType[newName] = {
        ...statusType[statusName],
      };
      delete statusType[statusName];
    })
    .then(() => {
      tasksRef.update({
        statusOrder,
        statusType,
      });
    });
};

export const changePhaseName = (spaceId,
  stationId,
  statusId,
  newName,
) => {

  // let keys = Object.keys(statusTypeCheck);
  // if (statusName === newName) {
  //   alert("same name");
  //   return;
  // }
  // if (keys.includes(newName)) {
  //   alert("name is already takaen");
  //   return;
  // }

  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId)
    .update({
      name: newName,
    })

}

export const deleteStatusType = (spaceId, stationId, statusId) => {
  // let statusOrder = [];
  // let newOrder = [];
  // let statusType = {};
  // let tasks = {};
  // let tasksWithin = [];

  const phaseRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId);

  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     tasksWithin = taskData.data().taskIds;

  //     db.
  //       collection("space")
  //       .doc(spaceId)
  //       .collection("stations")
  //       .doc(stationId)
  //       .collection("tasks")
  //       .doc(statusId);



  // get all data
  // statusOrder = taskData.data().statusOrder;
  // statusType = taskData.data().statusType;
  // tasks = taskData.data().tasks;

  // // set status order
  // newOrder = statusOrder.filter((item) => item !== statusName);
  // console.log(newOrder);

  // // set status type
  // delete statusType[statusName];
  // console.log(statusType);
  // })
  // .then(() => {
  //   tasksRef.update({
  //     statusOrder: [...newOrder],
  //     statusType,
  //   });
  // });

  //DELETE THE TASKS WITHIN THE PHASE
  // First perform the query
  db.
    collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks").where("phase", "==", statusId).get()
    .then(function (querySnapshot) {
      // Once we get the results, begin a batch
      var batch = db.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    }).then(function () {
      // Delete completed!
      // ...
    });
  phaseRef.delete();

  //DELETE the Phase
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId).delete();
  //Remove Phase from the Phase Order
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .update({
      phaseOrder: firebase.firestore.FieldValue.arrayRemove(statusId),
    });
};

export const createNewPhase = async (spaceId, stationId, newName) => {
  // let statusOrder = [];
  // let statusType = {};

  const phasesRef = db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")

  await phasesRef
    .add({
      name: newName,
      open: true,
      fontColor: "",
      taskIds: [],
    })
    .then((data) => {
      let id = data.id;
      phasesRef
        .doc(id).set(
          {
            id: id,
          },
          { merge: true }
        );

      db.collection("space")
        .doc(spaceId)
        .collection("stations")
        .doc(stationId)
        .update({
          phaseOrder: firebase.firestore.FieldValue.arrayUnion(id),
        });
    });



  // const phasesRef = db.collection("space")
  //   .doc(spaceId)
  //   .collection("stations")
  //   .doc(stationId)
  //   .collection("phases")
  //   .doc(newName)

  // phasesRef.get()
  //   .then((docSnapshot) => {
  //     if (docSnapshot.exists) {
  //       alert("Name is already taken, please choose a different name...");
  //       return;
  //     } else {
  //       db
  //         .collection("space")
  //         .doc(spaceId)
  //         .collection("stations")
  //         .doc(stationId)
  //         .collection("phases")
  //         .doc(newName)
  //         .set({
  //           id: newName,
  //           name: newName,
  //           open: true,
  //           fontColor: "",
  //           taskIds: [],
  //         }) // create the document
  //     }
  //   });

  // db.collection("space")
  //   .doc(spaceId)
  //   .collection("stations")
  //   .doc(stationId)
  //   .update({
  //     phaseOrder: firebase.firestore.FieldValue.arrayUnion(newName),
  //   });


  //////

  // db
  //   .collection("space")
  //   .doc(spaceId)
  //   .collection("stations")
  //   .doc(stationId)
  //   .collection("phases")
  //   .doc(newName)
  //   .set({
  //     id: newName,
  //     name: newName,
  //     open: true,
  //     fontColor: "",
  //     taskIds: [],
  //   })

  // db
  //   .collection("space")
  //   .doc(spaceId)
  //   .collection("stations")
  //   .doc(stationId)
  //   .collection("phases")
  //   .add({
  //     id: newName,
  //     name: newName,
  //     open: true,
  //     fontColor: "",
  //     taskIds: [],
  //   })

  // .get()
  // .then((taskData) => {
  //   // get all data
  //   statusOrder = taskData.data().statusOrder;
  //   statusType = taskData.data().statusType;

  //   // chekc conditions
  //   if (statusOrder.includes(newName)) {
  //     alert("name allready taken");
  //     return;
  //   }

  //   // set status order
  //   statusOrder.push(newName);

  //   // set status type
  //   statusType = {
  //     ...statusType,
  //     [newName]: {
  //       color: "rgb(234, 236, 239)",
  //       fontColor: "rgb(246, 91, 196)",
  //       id: newName,
  //       name: newName,
  //       taskIds: [],
  //       open: true,
  //     },
  //   };
  //   console.log(statusType);
  // })
  // .then(() => {
  //   tasksRef.update({
  //     statusOrder,
  //     statusType,
  //   });
  // });
};
export const createNewStatus = (spaceId, stationId, newName) => {
  let statusOrder = [];
  let statusType = {};

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      // get all data
      statusOrder = taskData.data().statusOrder;
      statusType = taskData.data().statusType;

      // chekc conditions
      if (statusOrder.includes(newName)) {
        alert("name allready taken");
        return;
      }

      // set status order
      statusOrder.push(newName);

      // set status type
      statusType = {
        ...statusType,
        [newName]: {
          color: "rgb(234, 236, 239)",
          fontColor: "rgb(246, 91, 196)",
          id: newName,
          name: newName,
          taskIds: [],
          open: true,
        },
      };
      console.log(statusType);
    })
    .then(() => {
      tasksRef.update({
        statusOrder,
        statusType,
      });
    });
};

export const setTaskColor = (spaceId, stationId, statusId, newColor) => {
  // console.log("setting new color", newColor);
  // let statusType = {};

  // const tasksRef = 
  db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId)
    .update({
      fontColor: newColor
    })

  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     // get all data
  //     statusType = taskData.data().statusType;

  //     // set status type
  //     statusType = {
  //       ...statusType,
  //       [statusName]: {
  //         ...statusType[statusName],
  //         fontColor: newColor,
  //       },
  //     };
  //     console.log(statusType);
  //   })
  //   .then(() => {
  //     tasksRef.update({
  //       statusType,
  //     });
  //   });
};

export const updateUser = (userId, user) => {
  const userRef = db.collection("users").doc(userId);
  userRef.update({
    ...user,
  });
};

export const updateUserNotfSettings = (userId, notfSettings) => {
  const userRef = db.collection("users").doc(userId);
  userRef.update({
    notfSettings: notfSettings
  });
};

export const getTaskData = (spaceId, stationId) => {
  return db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .get()
    .then((data) => {
      return data.data();
    });
};

export const removeStarFavorite = (userId, stationId) => {
  db.collection("users")
    .doc(userId)
    .update({
      favoriteStations: firebase.firestore.FieldValue.arrayRemove(stationId),
    });
};
export const addStarFavorite = (userId, stationId) => {
  db.collection("users")
    .doc(userId)
    .update({
      favoriteStations: firebase.firestore.FieldValue.arrayUnion(stationId),
    });
};

/* export const unAssignFromAllTasks = (assignedArray, spaceId, userId) => {
  let mustRemoveTasks = assignedArray.filter(
    (item) => item.fromSpaceId === spaceId
  );
  console.log(mustRemoveTasks);
  mustRemoveTasks.map((task) => {
    let allTasks = {};
    const { fromSpaceId, fromStationId } = task;
    const tasksRef = db
      .collection("space")
      .doc(fromSpaceId)
      .collection("stations")
      .doc(fromStationId)
      .collection("tasks")
      .doc("tasks");
 
    tasksRef
      .get()
      .then((tasksData) => {
        allTasks = tasksData.data().tasks;
        allTasks[task.id].assign = [];
      })
      .then(() => {
        tasksRef.set(
          {
            tasks: {
              ...allTasks,
            },
          },
          { merge: true }
        );
      });
  });
}; */

export const createMessageToTask = (
  spaceId,
  stationId,
  newMessage,
  userId,
  taskId,
  fromName
) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .collection("comments")
    .add({
      message: newMessage,
      from: userId,
      created: fieldValue.serverTimestamp(),
      taskId: taskId,
      reaction: "",
      fromName: fromName,
    });
};

export const createCommentToTask = (
  spaceId,
  stationId,
  newComment,
  userId,
  taskId,
  fromName
) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc(taskId)
    .collection("comments")
    .add({
      message: newComment,
      from: userId,
      created: fieldValue.serverTimestamp(),
      fromtaskId: taskId,
      reaction: "",
      fromName: fromName,
    });
};

export const createMessageToWorkspace = (
  spaceId,
  newMessage,
  userId,
  sendto,
  sendtomode,
  fromName,
  fromImg,
  sendtoName
) => {
  db.collection("space")
    .doc(spaceId)
    .collection("chats")
    .add({
      message: newMessage,
      from: userId,
      fromImg: fromImg,
      fromName: fromName,
      created: fieldValue.serverTimestamp(),
      fromSpaceId: spaceId,
      reaction: "",
      to: sendto,
      toName: sendtoName,
      type: sendtomode,
    });
};
export const createMessageToProject = (
  spaceId,
  stationId,
  newMessage,
  userId,
  sendto,
  sendtomode,
  fromName,
  fromImg,
  projectName
) => {
  db.collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("chats")
    .add({
      message: newMessage,
      from: userId,
      fromImg: fromImg,
      fromName: fromName,
      created: fieldValue.serverTimestamp(),
      fromSpaceId: spaceId,
      reaction: "",
      to: sendto,
      toName: projectName,
      type: sendtomode,
    });
};
export const createMessageToPerson = (
  newMessage,
  userId,
  sendto,
  fromName,
  fromImg,
) => {
  // const convRefId1 = userId + sendto
  // const convRefId2 = sendto + userId

  let convRefId =
    userId < sendto
      ? userId + sendto
      : sendto + userId;

  db.collection("dmchat")
    .add({
      message: newMessage,
      from: userId,
      fromImg: fromImg,
      fromName: fromName,
      created: fieldValue.serverTimestamp(),
      reaction: "",
      to: sendto,
      conversationRef: convRefId,
    });
};
// export const createMessageToStation = (
//   spaceId,
//   stationId,
//   newMessage,
//   userId,
// ) => {
//   db.collection("space")
//     .doc(spaceId)
//     .collection("stations")
//     .doc(stationId)
//     .collection("chats")
//     .add({
//       message: newMessage,
//       from: userId,
//       created: new Date(),
//     });
// };

// export const createDiscussionToStation = (
//   spaceId,
//   newMessage,
//   userId,
// ) => {
//   db.collection("space")
//     .doc(spaceId)
//     .collection("discussion")
//     .add({
//       message: newMessage,
//       from: userId,
//       created: new Date(),
//       spaceId: spaceId,
//     });
// };

export const toggleStatus = (spaceId, stationId, statusId) => {
  let statusType = {};
  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("phases")
    .doc(statusId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        return doc.ref.update({ open: !doc.data().open });
      } else {
        // Throw an error
      }
    })
  // .then(function () {
  //   console.log('Todo successfully updated!');
  // })
  // .catch(function (error) {
  //   // The document probably doesn't exist.
  //   console.error('Error updating todo: ', error);
  // });
  // tasksRef
  //   .get()
  //   .then((taskData) => {
  //     statusType = taskData.data().statusType;
  //     statusType = {
  //       ...statusType,
  //       [statusName]: {
  //         ...statusType[statusName],
  //         open: !statusType[statusName].open,
  //       },
  //     };
  //   })
  //   .then(() => {
  //     tasksRef.update({
  //       statusType,
  //     });
  //   });
};

export const setOpenFb = (userId, currentOpen) => {
  db.collection("users").doc(userId).update({
    open: !currentOpen,
  });
};

export const setTimerOpen = (userId, currentOpen) => {
  db.collection("users").doc(userId).update({
    timerOpen: !currentOpen,
  });
};

export const setTaskDescription = (
  spaceId,
  stationId,
  taskId,
  taskDescription
) => {
  let task = {};
  let tasks = {};
  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      task = taskData.data().tasks[taskId];
      tasks = taskData.data().tasks;
      console.log(taskData.data());
      tasks = {
        ...tasks,
        [taskId]: {
          ...task,
          description: taskDescription,
        },
      };
    })
    .then(() => {
      console.log(tasks);
      tasksRef.update({
        tasks: {
          ...tasks,
        },
      });
    });
};

export const toggleCheckBox = async (
  spaceId,
  stationId,
  taskId,
  currentCheck
) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("space")
    .doc(spaceId)
    .collection("stations")
    .doc(stationId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        done: !currentCheck,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export { db, auth, firebase, LoginWithGoogle, loginWithEmailAndPassword, createNewSpace, firepadRef, functions };
